import { Card } from "react-bootstrap";
import React from "react";
import { useRecoilValue } from "recoil";
import { managedTeamState } from "components/shared/States";

export default function CardFaults() {
  const managedTeam = useRecoilValue(managedTeamState);

  // get only active players as we don't care about faults for inactive players
  const activePlayers = managedTeam.activePlayers;
  const faults = activePlayers.filter(
    (player) => player.mouthpiece_counts.critical_faults > 0
  );

  return (
    <Card className="shadow-sm bg-white rounded mb-4">
      <Card.Body className="text-center">
        <Card.Title className="text-secondary">Players with Faults</Card.Title>
        <h1 className="display-3 text-danger">{faults.length}</h1>
      </Card.Body>
    </Card>
  );
}
