import Alert from "react-bootstrap/Alert";
import { Auth } from "aws-amplify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

export default function ConfirmSignInForm({
  setChallenge,
  setUnconfirmedUser,
  setGroup,
  unconfirmedUser,
}) {
  const [alert, setAlert] = useState(null);
  const formInput = useRef();

  function setInputFocus() {
    formInput.current.focus();
  }

  useEffect(() => {
    setInputFocus();
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: (values) => {
      Auth.confirmSignIn(unconfirmedUser, values.code)
        .then((user) => {
          if (alert) setAlert(null);
          setChallenge(null);
          setUnconfirmedUser(null);
          const groups =
            user.signInUserSession.accessToken.payload["cognito:groups"];
          if (groups.find((group) => /(biocore|chop)/.test(group))) {
            setGroup("biocore");
          } else if (groups.find((group) => /team/.test(group))) {
            setGroup("team");
          }
        })
        .catch((err) => {
          switch (err.code) {
            case "CodeMismatchException":
              setAlert("Code is invalid.");
              break;
            default:
              setAlert("Something went horribly wrong...");
          }
        });
    },
    validationSchema: yup.object().shape({
      code: yup
        .string()
        .length(6, "Code must be 6 digits.")
        .required("Code is required."),
    }),
  });

  return (
    <div className="align-items-center d-flex full-screen justify-content-center">
      <div>
        {alert ? (
          <div className="d-flex justify-content-center mb-4">
            <Alert variant="danger">{alert}</Alert>
          </div>
        ) : null}
        <div className="d-flex justify-content-center">
          <Form
            className="centered-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Form.Group>
              <Form.Label>MFA Code</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.code && formik.touched.code}
                name="code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.code}
                ref={formInput}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.code}
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="mt-4 block" type="submit" variant="primary">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
