import useSWR from "swr";
import React, { Suspense, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Select from "react-select";
import ErrorBoundary from "components/shared/ErrorBoundary";
import LoadingSpinner from "components/shared/Spinner";
import CardFaults from "components/views/team/components/CardFaults";
import CardLowInventory from "components/views/team/components/CardLowInventory";
import CardOrders from "components/views/team/components/CardOrders";
import PlayerCards from "components/views/team/components/activePlayers/PlayerCards";
// import PlayerCardsInactive from "components/views/team/components/inactivePlayers/PlayerCardsInactive";
import TeamOrdersTable from "components/views/team/components/orders/TeamOrdersTable";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import makeAnimated from "react-select/animated";
import config from "config";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  activePlayersListState,
  allPlayersListState,
  cognitoGroupState,
  currentOrgState,
  filteredPlayersListState,
  inActivePlayersListState,
  managedTeamState,
  playerDetailsIdState,
  showPlayerDetailsState,
  teamsActiveTabState,
} from "components/shared/States";

export default function Team() {
  // basic state setup
  const activeOrgs = config.activeOrganizations;
  const managedTeam = useRecoilValue(managedTeamState);
  const cognitoGroup = useRecoilValue(cognitoGroupState);
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const [activeTab, setActiveTab] = useRecoilState(teamsActiveTabState);
  const [currentOrg, setCurrentOrg] = useRecoilState(currentOrgState);
  const setPlayerDetailsId = useSetRecoilState(playerDetailsIdState);
  const setAllPlayersList = useSetRecoilState(allPlayersListState);
  const setActivePlayersList = useSetRecoilState(activePlayersListState);
  const setInActivePlayersList = useSetRecoilState(inActivePlayersListState);
  const [filteredPlayersList, setFilteredPlayersList] = useRecoilState(
    filteredPlayersListState
  );

  // load the data and then set it into recoil state management objects
  const { data: allPlayers } = useSWR("players/" + currentOrg);

  useEffect(() => {
    setAllPlayersList(allPlayers);
    setActivePlayersList(managedTeam.activePlayers);
    setInActivePlayersList(managedTeam.inactivePlayers);
    if (filteredPlayersList.length === 0) {
      setFilteredPlayersList(managedTeam.activePlayers);
    }
  }, [
    managedTeam,
    cognitoGroup,
    allPlayers,
    currentOrg,
    setCurrentOrg,
    filteredPlayersList,
    setActivePlayersList,
    setAllPlayersList,
    setInActivePlayersList,
    setFilteredPlayersList,
  ]);

  // Tabs components
  const tabs = [
    {
      component: <PlayerCards />,
      key: "player-cards",
      title: "Active Players",
    },
    // {
    //   component: <PlayerCardsInactive />,
    //   key: "player-cards-inactive",
    //   title: "Inactive Players",
    // },
    {
      component: <TeamOrdersTable />,
      key: "team-orders",
      title: "Mouthpiece Orders",
    },
  ];

  // set up for Teams dropdown
  const animatedComponents = makeAnimated();
  const selectOptions = [];
  const defaultValue = { value: currentOrg, label: currentOrg };
  if (cognitoGroup === "biocore") {
    activeOrgs.forEach((organization) => {
      selectOptions.push({ value: organization, label: organization });
    });
  }

  /**
   * When the user changes teams we need to clean some state items up
   * and move the player details div around so it doesn't get lost
   * @param event
   */
  function handleTeamChange(event) {
    setFilteredPlayersList([]);
    setShowPlayerDetails(false);
    setPlayerDetailsId(0);
    let parentDiv = document.getElementById("player-cards-details-wrapper");
    let newNode = document.getElementById("player-details");
    parentDiv.appendChild(newNode);
    setActiveTab("player-cards");
    setCurrentOrg(event.value);
  }

  return (
    <>
      {cognitoGroup === "biocore" ? (
        <Row>
          <Col xs={24} sm={12} md={8}>
            <div style={{ maxWidth: "300px" }} className="mb-4">
              <label className="d-none">Choose Team:</label>
              <Select
                components={animatedComponents}
                options={selectOptions}
                className="basic-multi-select mx-auto"
                classNamePrefix="select"
                onChange={(e) => {
                  handleTeamChange(e);
                }}
                defaultValue={defaultValue}
              />
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="mb-5">
        <Col xs={24} sm={12} md={8}>
          <ErrorBoundary className="quarter-screen">
            <Suspense fallback={<LoadingSpinner className="quarter-screen" />}>
              <CardFaults allPlayers={allPlayers} />
            </Suspense>
          </ErrorBoundary>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <ErrorBoundary className="quarter-screen">
            <Suspense fallback={<LoadingSpinner className="quarter-screen" />}>
              <CardLowInventory allPlayers={allPlayers} />
            </Suspense>
          </ErrorBoundary>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ErrorBoundary className="quarter-screen">
            <Suspense fallback={<LoadingSpinner className="quarter-screen" />}>
              <CardOrders />
            </Suspense>
          </ErrorBoundary>
        </Col>
      </Row>
      <Row className="team-information">
        <Col>
          <Tabs
            transition={false}
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            {tabs.map((tabs) => {
              const { component, key, title } = tabs;
              return (
                <Tab eventKey={key} key={key} title={title} className={key}>
                  <div
                    className={"tab-wrapper"}
                    id={title.toLowerCase().replace(" ", "-")}
                  >
                    <ErrorBoundary className="half-screen">
                      <Suspense
                        fallback={<LoadingSpinner className="half-screen" />}
                      >
                        {component}
                      </Suspense>
                    </ErrorBoundary>
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}
