import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import DDRMsModal from "./DDRMsModal";
import { MultiSelectColumnFilter, SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  ddrmsDataState,
  filteredDDRMsDataState,
} from "components/shared/States";

export default function DDRMsTable() {
  const [row, setRow] = useState(null);
  const setDdrmsData = useSetRecoilState(ddrmsDataState);
  const filteredDDRMsData = useRecoilValue(filteredDDRMsDataState);

  const columns = useMemo(
    () => [
      {
        Header: "DDRM",
        columns: [
          {
            accessor: "ddrm_serial_number",
            Header: "Serial",
            Cell: ({ cell }) =>
              cell.row.original.ddrm_serial_number ? (
                <div className="link">
                  {cell.row.original.ddrm_serial_number}
                </div>
              ) : null,
          },
          {
            accessor: (d) => d.mac_address.toUpperCase(),
            Header: "MAC",
            id: "mac_address",
          },
          {
            accessor: (d) => (d.firmware_version ? d.firmware_version : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Firmware",
            id: "firmware_version",
          },
          {
            accessor: "part_number",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Part",
          },
          {
            accessor: "revision",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Revision",
          },
          {
            accessor: "potting",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Potting",
          },
          {
            accessor: (d) => (d.functional ? "TRUE" : "FALSE"),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Functional",
            id: "functional",
          },
          {
            accessor: "location",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Location",
          },
          {
            accessor: "allocation_type",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Allocation",
          },
          {
            accessor: "calibration_type",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Calibration",
          },
          {
            accessor: "comments",
            Header: "Comments",
          },
        ],
      },
      {
        Header: "Mouthpiece",
        columns: [
          {
            accessor: "mouthpiece_serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) =>
              d.mouthpiece_serial_number
                ? d.mouthpiece_functional
                  ? "YES"
                  : "NO"
                : " ",
            Header: "Functional",
          },
          {
            accessor: "mouthpiece_comments",
            Header: "Comments",
          },
        ],
      },
      {
        Header: "Player",
        columns: [
          {
            accessor: "mgs_player_id",
            Header: "ID",
          },
          {
            accessor: (d) =>
              d.last_name != null ? d.last_name + ", " + d.first_name : " ",
            Header: "Name",
          },
          {
            accessor: "organization",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Organization",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: (d) =>
              d.mgs_player_id
                ? d.study_participation
                  ? "ACTIVE"
                  : "INACTIVE"
                : " ",
            Header: "Status",
            Filter: SelectColumnFilter,
            filter: "exactText",
            id: "study_participation",
          },
        ],
      },
    ],
    []
  );

  const { data: ddrms } = useSWR("ddrms");
  const { data: organizations } = useSWR("organizations");

  // pass data into Recoil state holder
  // it will then be revised to add the Revision data
  // based on the part number
  useEffect(() => {
    setDdrmsData(ddrms);
  });

  return (
    <>
      <Table
        className="full-screen ddrms"
        columns={columns}
        data={filteredDDRMsData}
        onClick={setRow}
      />
      {row ? (
        <DDRMsModal organizations={organizations} row={row} setRow={setRow} />
      ) : null}
    </>
  );
}
