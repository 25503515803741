import Alert from "react-bootstrap/Alert";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { CSVLink } from "react-csv";
import { CSVReader } from "react-papaparse";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import * as yup from "yup";
import config from "config";
import { SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";
import { Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { buildErrorsList, targetDeliveryDate } from "utils/helpers";

export default function MouthpiecesOrderTable() {
  const [alert, setAlert] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [show, setShow] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  // currently pulling orgs from config
  const organizations = config.canOrderMouthGuardsOrgs;

  // set up target date
  let todaysDate = new Date();
  let targetDelDate = targetDeliveryDate(todaysDate, 8);
  const isoTargetDate = todaysDate.toISOString().split("T")[0];

  // set up seasonYear and nextSeason year for regular expression validation
  const seasonYear = JSON.parse(config.activeSeason);
  const nextSeasonYear = seasonYear + 1;
  const re = new RegExp("^(" + seasonYear + "|" + nextSeasonYear + "|)$");

  /**
   * Table setup
   */
  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: (d) => (d.valid ? "True" : "False"),
            Cell: ({ cell }) => {
              const valid = cell.row.original.valid;
              const color = valid ? "green" : "darkred";
              const icon = valid ? faCheckCircle : faExclamationCircle;
              return <FontAwesomeIcon color={color} icon={icon} />;
            },
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Valid",
            id: "valid",
          },

          {
            accessor: (d) => d.errors,
            Cell: ({ cell }) => {
              if (Array.isArray(cell.row.original.errors)) {
                const errorsList = buildErrorsList(cell.row.original.errors);
                return (
                  <ul
                    style={{ textAlign: "left", paddingLeft: 0 }}
                    dangerouslySetInnerHTML={{ __html: errorsList }}
                  />
                );
              } else {
                return null;
              }
            },
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Errors",
            id: "errors",
          },
          {
            accessor: (d) =>
              d.response
                ? d.response.status === 201
                  ? "Success"
                  : "Errors"
                : "Unsubmitted",
            Cell: ({ cell }) => {
              const response = cell.row.original.response;
              let color, icon, success;
              if (response) {
                success = response.status === 201;
                color = success ? "green" : "red";
                icon = success ? faCheckCircle : faTimesCircle;
                return <FontAwesomeIcon color={color} icon={icon} />;
              } else return null;
            },
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Submitted",
          },
        ],
        Header: "Status",
      },
      {
        columns: [
          {
            accessor: "mgs_player_id",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "ID",
          },
        ],
        Header: "Player",
      },
      {
        columns: [
          {
            accessor: "colors",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Colors",
          },
          {
            accessor: "electronics",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Electronics",
          },
          {
            accessor: "type",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Type",
          },
        ],
        Header: "Mouthpiece",
      },
      {
        columns: [
          {
            accessor: "recipient",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Recipient",
          },
          {
            accessor: "account",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Account",
          },
          {
            accessor: "requested_delivery_date",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Requested Delivery",
          },
          {
            accessor: "season",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Season",
          },
          {
            accessor: (d) => (d.details ? d.details : " "),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Details",
            id: "details",
          },
        ],
        Header: "Order",
      },
    ],
    []
  );

  /**
   * Creates the CSV template the user can download
   * @type {{headers: string[], filename: string, data: *[]}}
   */
  const template = {
    data: [],
    filename: "order_template.csv",
    headers: [
      "mgs_player_id",
      "colors",
      "electronics",
      "type",
      "manufacturer",
      "details",
      "season",
      "recipient",
      "account",
      "requested_delivery_date",
      "status",
    ],
  };

  /**
   * Resets the data and removes table modal if the user cancels
   */
  const handleClearClick = () => {
    setIsReset(true);
    setData([]);
    setSubmitDisabled(true);
  };

  /**
   * Handles the submission of the data to the REST API
   */
  const handleConfirmClick = () => {
    setLoading(true);
    setConfirmDisabled(true);

    Promise.allSettled(
      data.map(async (row) => {
        if (row.valid) {
          const body = JSON.parse(JSON.stringify(row));
          delete body.response;
          delete body.valid;
          return axios.post("mouthpieces", [body]);
        } else {
          return null;
        }
      })
    )
      .then((outcome) => {
        setLoading(false);
        setData(
          data.map((row, index) => {
            row.response =
              outcome[index].status === "fulfilled"
                ? outcome[index].value
                : { status: parseInt(outcome[index].reason) };
            return row;
          })
        );
        setSubmitDisabled(true);
        setShow(false);
      })
      .catch((error) => {
        console.log(error);
        setErrored(true);
      });
  };

  /**
   * Checks to ensure that the file is the correct type and validates the data
   * @param orders
   * @param file
   */
  const handleFileDrop = (orders, file) => {
    if (
      !["application/vnd.ms-excel", "text/csv", "text/plain", ".csv"].includes(
        file.type
      )
    ) {
      return setAlert("Invalid file type.");
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    /**
     * Validates the file input data
     */
    const schema = yup.object({
      mgs_player_id: yup.number().integer().min(1).required(),
      colors: yup
        .string()
        .matches(/^[A-Z]{3}$/)
        .required(),
      electronics: yup
        .string()
        .matches(
          /^(DUMMY_C|DUMMY_D|FUNCTIONAL_B|FUNCTIONAL_C|FUNCTIONAL_D|FUNCTIONAL_E|NONE)$/
        )
        .required(),
      type: yup
        .string()
        .matches(/^(MOUTHGUARD|RETAINER)$/)
        .required(),
      recipient: yup
        .string()
        .test("organization", "Organization not recognized.", (value) => {
          return config.canOrderMouthGuardsOrgs.includes(value);
        })
        .required(),
      account: yup
        .string()
        .matches(/^(BIO_NSWG|BIO_RFU|BIO_SLE|BIO_USAF|FRI_NCAA|FRI_NFL)$/)
        .required(),
      requested_delivery_date: yup.date().min(isoTargetDate).required(),
      season: yup.string().matches(re).required(),
      status: yup.string().matches(/^(REQUESTED|APPROVED)$/),
      manufacturer: yup.string().matches(/^(OPRO|GL)$/),
      details: yup.string().max(500),
    });

    setIsReset(false);

    Promise.allSettled(
      orders.map(async (order) => {
        await schema.validate(order.data, { abortEarly: false });
      })
    )
      .then((outcome) => {
        console.log(outcome);
        setData(
          orders.map((order, index) => {
            order.data.requested_delivery_date = new Date(
              Date.parse(order.data.requested_delivery_date)
            )
              .toISOString()
              .split("T")[0];
            order.data.details = order.data.details.trim()
              ? order.data.details.trim()
              : null;
            order.data.response = null;
            order.data.valid = outcome[index].status === "fulfilled";
            order.data.errors =
              outcome[index].status === "rejected"
                ? outcome[index].reason.errors
                : false;
            return order.data;
          })
        );
      })
      .catch((error) => {
        console.log("setDataError", error);
      });
    setSubmitDisabled(false);
  };

  /**
   * Closes modal window
   */
  const handleModalClose = () => {
    setConfirmDisabled(true);
    setShow(false);
  };

  /**
   * Triggers showing of confirmation modal
   */
  const handleSubmitClick = () => {
    setConfirmDisabled(false);
    setShow(true);
  };

  return (
    <>
      <div
        className={
          data.length
            ? "d-none"
            : "align-items-start d-flex justify-content-center"
        }
      >
        <div className="align-items-start d-flex flex-row">
          <Col>
            <div className="align-items-center d-flex flex-column justify-content-center text-left">
              <div className="instructions mt-4">
                <h4>Player Template Instructions</h4>
                <p>
                  To properly format the players file, please use the following
                  guide:
                </p>
                <ol className="w-full-width">
                  <li className="mb-3">
                    Player ID should be the MGS ID of the player
                  </li>
                  <li className="mb-3">
                    Colors should be three capital characters (e.g.{" "}
                    <pre className="d-inline">AAA</pre>)
                  </li>
                  <li className="mb-3">
                    Electronics should be{" "}
                    <pre className="d-inline">
                      DUMMY_C, DUMMY_D, FUNCTIONAL_C, FUNCTIONAL_D,
                      FUNCTIONAL_E,
                    </pre>{" "}
                    or <pre className="d-inline">NONE</pre>
                  </li>
                  <li className="mb-3">
                    Type should be <pre className="d-inline">MOUTHGUARD</pre> or{" "}
                    <pre className="d-inline">RETAINER</pre>
                  </li>
                  <li className="mb-3">
                    Manufacturer should be <pre className="d-inline">OPRO</pre>
                  </li>
                  <li className="mb-3">
                    Details (additional information for manufacturing) should be
                    separated by semi-colon for parsing by the manufacturer
                  </li>
                  <li className="mb-3">
                    Season should be{" "}
                    <pre className="d-inline">{seasonYear}</pre> or{" "}
                    <pre className="d-inline">{nextSeasonYear}</pre>
                  </li>
                  <li className="mb-3">
                    Recipient should be any of these organization abbreviations:
                    <br />
                    {organizations.map((org, index) => (
                      <>
                        {/*<span className="me-1">{org.abbreviation},</span>*/}
                        <pre className="d-inline">
                          <span className="me-1">
                            {org}
                            {index < organizations.length - 1 ? (
                              <span key={index}>,</span>
                            ) : (
                              ""
                            )}
                          </span>
                        </pre>
                        {(index + 1) % 20 === 0 ? <br /> : ""}
                      </>
                    ))}
                  </li>
                  <li className="mb-3">
                    Account should be{" "}
                    <pre className="d-inline">
                      BIO_NSWG, BIO_RFU, BIO_SLE, BIO_USAF, FRI_NCAA,
                    </pre>{" "}
                    or <pre className="d-inline">FRI_NFL</pre>
                  </li>
                  <li className="mb-3">
                    Requested delivery date should be anything greater or equal
                    to 8 business days from today's date:{" "}
                    <pre className="d-inline">{targetDelDate}</pre>
                  </li>
                  <li className="mb-3">
                    Status should be <pre className="d-inline">REQUESTED</pre>{" "}
                    or <pre className="d-inline">APPROVED</pre>
                  </li>
                </ol>
              </div>
            </div>
          </Col>
          <Col className="text-center">
            {alert ? (
              <div className="d-flex justify-content-center mb-4">
                <Alert variant="danger">{alert}</Alert>
              </div>
            ) : null}
            <div className="drop-zone mb-4 mt-4">
              <CSVReader
                addRemoveButton
                config={{ header: true, skipEmptyLines: "greedy" }}
                isReset={isReset}
                noClick
                noProgressBar
                onDrop={handleFileDrop}
                onError={(error) => console.log(error)}
                onRemoveFile={() => (alert ? setAlert("") : null)}
              >
                <span>Drag and drop a file here to import orders.</span>
              </CSVReader>
              <div className="mt-3">
                <CSVLink {...template}>
                  Download Mouthpiece Order Template
                </CSVLink>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {data.length ? (
        <>
          <Table
            className="full-screen-with-buttons mb-4"
            columns={columns}
            data={data}
          />
          <div className="d-flex justify-content-end">
            <Button
              className="me-2"
              onClick={handleClearClick}
              type="button"
              variant="secondary"
            >
              Clear
            </Button>
            <Button
              disabled={submitDisabled}
              onClick={handleSubmitClick}
              type="button"
              variant="danger"
            >
              Submit
            </Button>
          </div>

          <Modal
            animation={false}
            background="static"
            centered
            onHide={() => handleModalClose()}
            show={show}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Confirm Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>{`Place order for ${
              data.filter((row) => row.valid).length
            } mouthpiece(s)?`}</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => handleModalClose()}
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={confirmDisabled}
                onClick={() => handleConfirmClick()}
                type="button"
                variant="danger"
              >
                Confirm
                {loading === true ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="light"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  ""
                )}
                {errored === true ? (
                  <span className="errored">
                    <img
                      src="/img/icn-error-white.svg"
                      alt="errored..."
                      style={{ width: "20px", marginLeft: "2%" }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
}
