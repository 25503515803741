import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import React, { useState } from "react";
import config from "config";
import { useFormik } from "formik";
import * as yup from "yup";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentOrgState,
  playerDetailsIdState,
  showOrderMouthguardModalState,
} from "components/shared/States";
import Spinner from "react-bootstrap/Spinner";
import { titleCase } from "text-case";
import { mouthpieceColors, slugify, targetDeliveryDate } from "utils/helpers";

export default function OrderMouthpieceModal({
  totalMouthPiecesForPlayer,
  lastOrder,
}) {
  const todaysDate = new Date();
  const minTargetDate = targetDeliveryDate(todaysDate, 8);
  const seasonYear = config.activeSeason;
  const playerDetailsId = useRecoilValue(playerDetailsIdState);
  const currentOrg = useRecoilValue(currentOrgState);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const [
    showOrderMouthguardModal,
    setShowOrderMouthguardModal,
  ] = useRecoilState(showOrderMouthguardModalState);

  // set up quantity dropdown
  // temporarily comment out in case we want to come back to limiting order numbers
  // const quantityAvailableToOrder = 4 - totalMouthPiecesForPlayer;
  // const quantityAvailableToOrder = 5; // Alex likes to change his mind...a lot
  const quantityAvailableToOrder = 3;
  const quantityOptions = [];
  for (let i = 0; i < quantityAvailableToOrder; i++) {
    quantityOptions.push(i + 1);
  }

  // console.log("lastOrder", lastOrder);

  let initialValues = {
    account: lastOrder.account,
    colors: lastOrder.colors,
    dentition_quantity: 0,
    details: lastOrder.details,
    // electronics: lastOrder.electronics,
    electronics: "FUNCTIONAL_E",
    jaw: lastOrder.jaw ? "UPPER" : "LOWER",
    manufacturer: lastOrder.manufacturer,
    mgs_player_id: playerDetailsId,
    mouthguard_quantity: 1,
    recipient: "BIO",
    // recipient: currentOrg,
    requested_delivery_date: minTargetDate,
    season: seasonYear,
    status: "REQUESTED",
    tether_hole: titleCase(lastOrder.tether_hole.toString()),
    type: lastOrder.type === "RETAINER" ? "RETAINER" : "MOUTHGUARD",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      console.log("values", values);
      setLoading(true);

      const mouthguards = {};
      for (const key in values) {
        switch (key) {
          case "mgs_player_id":
            mouthguards[key] = playerDetailsId;
            break;
          default:
            mouthguards[key] = values[key];
        }
      }

      // console.log("mouthguards", mouthguards);

      const orders = [];
      for (let i = 0; i < values.mouthguard_quantity; i++) {
        let postData = {
          mgs_player_id: playerDetailsId,
          colors: lastOrder.colors,
          electronics: lastOrder.electronics,
          type: lastOrder.type,
          jaw: lastOrder.jaw,
          tether_hole: lastOrder.tether_hole,
          dentition_quantity: 0,
          manufacturer: lastOrder.manufacturer,
          account: lastOrder.account,
          details: lastOrder.details,
          season: lastOrder.season,
          // recipient: currentOrg,
          recipient: "BIO",
          requested_delivery_date: minTargetDate,
          status: "REQUESTED",
          ...mouthguards,
        };

        orders.push(postData);
      }

      axios
        .post("mouthpieces", orders)
        .then(() => {
          // update all the things!

          // data for orders table
          mutate(`mouthpieces/ordered/player/${playerDetailsId}`).then((r) => {
            // console.log("mouthpieces/ordered/player/playerId", r);
          });

          // data for ordered cards
          mutate(`mouthpieces/ordered/${currentOrg}`).then((r) => {
            // console.log("mouthpieces/ordered/currentOrg", r);
          });

          // data for the mouthpieces table for this player
          mutate(`mouthpieces/player/${playerDetailsId}`).then((r) => {
            // console.log("mouthpieces/player/playerId", r);
          });
          //
          mutate(`mouthpieces/organization/${currentOrg}`).then((r) => {
            // console.log("mouthpieces/organization/currentOrg", r);
          });

          formik.resetForm();
          setLoading(false);

          // close the modal window
          setShowOrderMouthguardModal(false);
        })
        .catch((error) => {
          setLoading(false);
          setErrored(true);
          console.log("mouthpieces order error", error.response);
        });
    },
    validationSchema: yup.object().shape({
      jaw: yup
        .string()
        .matches(/^(LOWER|UPPER)$/)
        .required(),
      mouthguard_quantity: yup.number().min(1).max(3).required(),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      show={showOrderMouthguardModal}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Order Mouthpiece`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  onChange={formik.handleChange}
                  defaultValue={lastOrder.type}
                  className="form-select"
                >
                  <option value="MOUTHGUARD">MOUTHGUARD</option>
                  <option value="RETAINER">RETAINER</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  as="select"
                  name="mouthguard_quantity"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.mouthguard_quantity}
                  className="form-select"
                >
                  {quantityOptions.map((object, i) => {
                    return (
                      <option value={object} key={i}>
                        {object}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <div className="col">
              <Form.Group>
                <Form.Label>Color</Form.Label>
                <div className="row">
                  {mouthpieceColors.map((color, i) => {
                    return (
                      <div className="col-6" key={color.uglyColor}>
                        <label className="mouthpiece-colors mb-3">
                          <span
                            className={
                              `color-block ` + slugify(color.uglyColor)
                            }
                          >
                            &nbsp;
                          </span>
                          <Form.Check
                            type="radio"
                            name="colors"
                            value={color.uglyColor}
                            onChange={formik.handleChange}
                            key={i}
                            className="d-inline-block me-1"
                            defaultChecked={
                              lastOrder.colors === color.uglyColor
                                ? true
                                : false
                            }
                          />
                          {color.prettyColor}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </Form.Group>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowOrderMouthguardModal(false);
              formik.resetForm();
            }}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            // disabled={disabled}
            type="submit"
            variant="danger"
          >
            Order
            {loading === true ? (
              <>
                <div className="ps-2 d-inline">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
