import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";

export default function MouthpiecesModal({ organizations, row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  // console.log('row: ', row)

  const initialValues = {
    mouthpiece_comments: row.mouthpiece_comments ? row.mouthpiece_comments : "",
    ddrm_comments: row.ddrm_comments ? row.ddrm_comments : "",
    delivery_date: row.delivery_date ? row.delivery_date : "",
    ddrm_functional: row.ddrm_serial_number
      ? row.ddrm_functional
        ? "TRUE"
        : "FALSE"
      : "",
    mouthpiece_functional: row.mouthpiece_functional
      ? row.mouthpiece_functional
        ? "TRUE"
        : "FALSE"
      : "",
    location: row.ddrm_serial_number ? row.location : "",
    status: row.status,
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const ddrms = {};
      const mouthpieces = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "mouthpiece_comments":
              mouthpieces["comments"] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "mouthpiece_functional":
              mouthpieces["functional"] = values[key] === "TRUE";
              break;
            case "ddrm_comments":
              ddrms["comments"] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "ddrm_functional":
              ddrms["functional"] = values[key] === "TRUE";
              break;
            case "location":
              ddrms[key] = values[key];
              break;
            default:
              mouthpieces[key] = values[key];
          }
        }
      }

      const requests = [];
      if (Object.keys(mouthpieces).length) {
        requests.push(
          axios.put("mouthpieces", {
            order_number: row.order_number,
            ...mouthpieces,
          })
        );
      }

      if (Object.keys(ddrms).length) {
        requests.push(
          axios.put("ddrms", {
            serial_number: row.ddrm_serial_number,
            ...ddrms,
          })
        );
      }

      axios
        .all(requests)
        .then(() => {
          mutate("mouthpieces").then(() => {
            setLoading(false);
            setErrored(false);
            setRow(null);
          });
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMsg(error.response);
          setLoading(false);
          setErrored(true);
        });
    },
    validate: function (values) {
      values.ddrm_comments = values.ddrm_comments.trim();
      values.mouthpiece_comments = values.mouthpiece_comments.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      ddrm_comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
      mouthpiece_comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
      delivery_date: yup
        .date()
        .test(
          "date_required",
          "Delivery is required.",
          function (delivery_date) {
            return !(this.parent.status === "Delivered" && !delivery_date);
          }
        ),
    }),
  });

  const options = [];
  switch (row.status) {
    case "APPROVED":
      options.push("APPROVED");
      break;
    case "CANCELED":
      options.push("CANCELED");
      break;
    case "DELIVERED":
      options.push("DELIVERED", "RETIRED", "DESTROYED");
      break;
    case "MANUFACTURING":
      options.push("DELIVERED", "MANUFACTURING", "SHIPPED");
      break;
    case "REQUESTED":
      options.push("APPROVED", "REQUESTED", "CANCELED");
      break;
    case "SHIPPED":
      options.push("SHIPPED", "DELIVERED");
      break;
    case "RETIRED":
      options.push("DELIVERED", "RETIRED", "DESTROYED");
      break;
    case "DESTROYED":
      options.push("DESTROYED");
      break;
    default:
  }

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {`Edit Order ${row.order_number}`} | Serial: {row.serial_number} |
            DDRM Serial: {row.ddrm_serial_number}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  disabled={options.length < 2}
                  name="status"
                  onChange={(event) => {
                    const value = event.target.value;
                    return formik.setFieldValue("status", value).then(() => {
                      // TODO: Ask Alex what the point of this code was
                      // if (value !== "DELIVERED" ) {
                      //   // formik.setFieldValue("delivery_date", "");
                      // }
                    });
                  }}
                  value={formik.values.status}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Delivery</Form.Label>
                <Form.Control
                  disabled={
                    formik.initialValues.delivery_date ||
                    formik.values.status !== "DELIVERED"
                  }
                  isInvalid={
                    !!formik.errors.delivery_date &&
                    formik.touched.delivery_date
                  }
                  name="delivery_date"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="date"
                  value={formik.values.delivery_date}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.delivery_date}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                >
                  {row.ddrm_serial_number ? (
                    organizations.map((organization) => {
                      const abbreviation = organization.abbreviation;
                      return (
                        <option key={abbreviation} value={abbreviation}>
                          {abbreviation}
                        </option>
                      );
                    })
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Mouthpiece Functional</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="mouthpiece_functional"
                  onChange={formik.handleChange}
                  value={formik.values.mouthpiece_functional}
                >
                  <option value="FALSE">FALSE</option>
                  <option value="TRUE">TRUE</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Mouthpiece Comments</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.mouthpiece_comments}
                name="mouthpiece_comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.mouthpiece_comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.mouthpiece_comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>DDRM Functional</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="ddrm_functional"
                  onChange={formik.handleChange}
                  value={formik.values.ddrm_functional}
                >
                  {row.ddrm_serial_number ? (
                    <>
                      <option value="FALSE">FALSE</option>
                      <option value="TRUE">TRUE</option>
                    </>
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <Form.Label>DDRM Comments</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.ddrm_comments}
                name="ddrm_comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.ddrm_comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ddrm_comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
          {errorMsg ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <Alert variant="danger">{errorMsg}</Alert>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update{" "}
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
