import React, { useMemo, useState } from "react";
import useSWR from "swr";
import MouthpiecesModal from "./MouthpiecesModal";
import { MultiSelectColumnFilter, SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function MouthpiecesTable() {
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Order",
        columns: [
          {
            accessor: "order_number",
            Header: "Number",
            Cell: ({ cell }) =>
              cell.row.original.order_number ? (
                <div className="link">{cell.row.original.order_number}</div>
              ) : null,
          },
          {
            accessor: "status",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Status",
          },
          {
            accessor: (d) => d.requested_delivery_date.split("T")[0],
            Header: "Requested Delivery",
            id: "requested_delivery_date",
          },
          {
            accessor: (d) =>
              d.delivery_date ? d.delivery_date.split("T")[0] : "",
            Header: "Delivery",
            id: "delivery_date",
          },
          {
            accessor: "tracking_number",
            Cell: ({ cell }) => {
              const trackingNumber = cell.row.original.tracking_number;
              if (trackingNumber && trackingNumber.length === 10) {
                return (
                  <a
                    href={`https://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                );
              } else if (trackingNumber && trackingNumber.length === 12) {
                return (
                  <a
                    href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                );
              } else if (trackingNumber) {
                return trackingNumber;
              } else {
                return null;
              }
            },
            Header: "Tracking",
          },
          {
            accessor: "recipient",
            Header: "Recipient",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
          },
          {
            accessor: "invoice_number",
            Header: "Invoice",
          },
          {
            accessor: "remanufacture",
            Header: "Remanufacture",
          },
        ],
      },

      {
        Header: "Mouthpiece",
        columns: [
          {
            accessor: "serial_number",
            Header: "Serial",
          },
          {
            accessor: "manufacturer",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Manufacturer",
          },
          {
            accessor: "type",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Type",
          },
          {
            accessor: "electronics",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Electronics",
          },
          {
            accessor: "colors",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Colors",
          },
          {
            accessor: "details",
            Header: "Specifications",
          },
          {
            accessor: "season",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Season",
          },
          {
            accessor: (d) =>
              d.mouthpiece_functional === null
                ? null
                : Boolean(d.mouthpiece_functional).toString().toUpperCase(),
            Header: "Functional",
            Filter: SelectColumnFilter,
            filter: "exactText",
          },
          {
            accessor: (d) => (d.location ? d.location : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Location",
            id: "location",
          },
          {
            accessor: "mouthpiece_comments",
            Header: "Comments",
          },
        ],
      },

      {
        Header: "DDRM",
        columns: [
          {
            accessor: "ddrm_serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) =>
              d.ddrm_functional === null
                ? null
                : Boolean(d.ddrm_functional).toString().toUpperCase(),
            Filter: SelectColumnFilter,
            filter: "exactText",
            // eslint-disable-next-line
            Header: " " + "Functional" + " ", // DataTables does not like headers with matching names. Must be unique
          },
          {
            accessor: "ddrm_comments",
            Header: "Comments",
          },
        ],
      },

      {
        Header: "Player",
        columns: [
          {
            accessor: "mgs_player_id",
            Header: "ID",
          },
          {
            accessor: (d) => d.last_name + ", " + d.first_name,
            Header: "Name",
          },
          {
            accessor: "organization",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Organization",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: (d) => (d.study_participation ? "ACTIVE" : "INACTIVE"),
            Header: "Status",
            Filter: SelectColumnFilter,
            filter: "exactText",
            id: "study_participation",
          },
        ],
      },
    ],
    []
  );

  const { data: mouthpieces } = useSWR("mouthpieces");
  const { data: organizations } = useSWR("organizations");

  // load custom initial state for this table
  // set the season to the current config.activeSeason value
  const initialState = useMemo(() => {
    return {
      sortBy: [
        {
          id: "order_number",
          desc: true,
        },
        {
          id: "season",
          desc: false,
        },
      ],
    };
  }, []);

  return (
    <>
      <Table
        className="full-screen mouthpieces"
        columns={columns}
        data={mouthpieces}
        initialState={initialState}
        onClick={setRow}
      />
      {row ? (
        <MouthpiecesModal
          organizations={organizations}
          row={row}
          setRow={setRow}
        />
      ) : null}
    </>
  );
}
