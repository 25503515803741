import Table from "components/shared/Table";
import React, { useMemo, useState, useCallback } from "react";
import {
  DefaultColumnFilter,
  SelectBooleanColumnFilter,
  SelectColumnFilter,
} from "utils/filters";
import { Button } from "react-bootstrap";
import EditMouthpieceModal from "components/views/team/components/activePlayers/details/EditMouthpieceModal";
import ResolveFaultsModal from "components/views/team/components/activePlayers/details/ResolveFaultsModal";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  ddrmSerialNumberState,
  faultReasonState,
  showResolveFaultsModalState,
} from "components/shared/States";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { getPrettyMouthpieceColor, slugify } from "utils/helpers";

export default function PlayerMouthpiecesTable({
  playerMouthpieces,
  playerDetailsId,
  playerDetails,
}) {
  const [row, setRow] = useState(null);
  const [ddrmSerialNumber, setDdrmSerialNumber] = useRecoilState(
    ddrmSerialNumberState
  );
  const setShowResolveFaultsModal = useSetRecoilState(
    showResolveFaultsModalState
  );

  const setFaultReason = useSetRecoilState(faultReasonState);

  const triggerResolveModal = useCallback(
    (ddrm_serial_number, faultReason) => {
      setDdrmSerialNumber(ddrm_serial_number);
      setShowResolveFaultsModal(true);
      setFaultReason(faultReason);
    },
    [setDdrmSerialNumber, setShowResolveFaultsModal, setFaultReason]
  );

  // if playerMouthpieces is an object, that means it's empty and this will error out
  // reset the variable to an empty array so the table renders ok
  if (!Array.isArray(playerMouthpieces)) {
    playerMouthpieces = [];
  }

  const columns = useMemo(
    () => [
      {
        accessor: "mouthpiece_serial_number",
        Header: "Serial",
        Filter: DefaultColumnFilter,
        filter: "exactText",
        Cell: ({ cell }) =>
          cell.row.original.mouthpiece_serial_number ? (
            <div className="link">
              {cell.row.original.mouthpiece_serial_number}
            </div>
          ) : null,
      },
      {
        accessor: (d) =>
          d.ddrm_functional === true && d.mouthpiece_functional
            ? "TRUE"
            : "FALSE",
        Header: "Functional",
        Filter: SelectColumnFilter,
        filter: "exactText",
      },
      {
        accessor: "fault",
        Header: "Faults",
        Filter: SelectBooleanColumnFilter,
        filter: "exactText",
        Cell: ({ cell }) =>
          cell.row.original.fault === true ? (
            <div className="negative-margin">
              <Button
                onClick={() => {
                  triggerResolveModal(
                    cell.row.original.ddrm_serial_number,
                    cell.row.original.fault_description
                  );
                }}
                size="sm"
                type="button"
                variant="danger"
              >
                <FontAwesomeIcon color="white" icon={faExclamationCircle} />
                <span className="text-white ps-2">Details</span>
              </Button>
            </div>
          ) : null,
      },
      {
        accessor: "mouthpiece_type",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Type",
      },
      {
        Header: "Color",
        Filter: DefaultColumnFilter,
        filter: "exactText",
        Cell: ({ cell }) =>
          cell.row.original.colors ? (
            <div>
              <span
                className={`color-block ` + slugify(cell.row.original.colors)}
              >
                &nbsp;
              </span>{" "}
              &nbsp;
              {getPrettyMouthpieceColor(cell.row.original.colors)}
            </div>
          ) : null,
      },
      {
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Status",
      },
      {
        accessor: "location",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Location",
      },
      {
        accessor: "ddrm_serial_number",
        Header: "DDRM Serial",
        Filter: DefaultColumnFilter,
        filter: "exactText",
        show: false, // uncomment to see DDRM Serial column
      },
    ],
    [triggerResolveModal]
  );

  return (
    <>
      <Table
        className=""
        columns={columns}
        data={playerMouthpieces}
        onClick={setRow}
      />
      {row ? (
        <EditMouthpieceModal
          id={playerDetailsId}
          organization={playerDetails.organization}
          row={row}
          setRow={setRow}
        />
      ) : null}
      {ddrmSerialNumber ? <ResolveFaultsModal /> : null}
    </>
  );
}
