import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";

import App from "./components/app/App";
import * as serviceWorker from "./serviceWorker";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_5gBX3yjKw",
    userPoolWebClientId: "3q0lul5qad1u0m2u9kabl067ab",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
