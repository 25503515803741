import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";

import {
  DefaultColumnFilter,
  NoColumnFilter,
  SelectColumnFilter,
} from "utils/filters";
import Table from "components/shared/Table";
import useSWR, { mutate } from "swr";
import { useRecoilValue } from "recoil";
import { currentOrgState } from "components/shared/States";
import { addOneDayOrPushToMonday } from "utils/helpers";

export default function TeamOrdersTable() {
  const [row, setRow] = useState(null);
  const [show, setShow] = useState(false);
  const currentOrg = useRecoilValue(currentOrgState);
  const { data: teamOrders } = useSWR(`mouthpieces/ordered/${currentOrg}`);

  /**
   * Handles form submission of receiving the mouthguard
   */
  const handleConfirmClick = () => {
    const today = new Date();
    axios
      .all([
        axios.put("ddrms", {
          serial_number: row.ddrm_serial_number,
          location: currentOrg,
        }),
        axios.put("mouthpieces", {
          order_number: row.order_number,
          delivery_date: `${today.getFullYear()}-${String(
            today.getMonth() + 1
          ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`,
          status: "DELIVERED",
        }),
      ])
      .then(() => {
        mutate(`mouthpieces/ordered/${currentOrg}`);
        handleModalClose();
      })
      .catch((err) => console.log(err));
  };

  /**
   * Handles trigger for closing the modal
   */
  const handleModalClose = () => {
    setRow(null);
    setShow(false);
  };

  /**
   * Handles receive button click
   */
  const handleReceiveClick = (row) => {
    setRow(row);
    setShow(true);
  };

  const columns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          cell.row.original.status === "SHIPPED" ? (
            <div className="negative-margin">
              <Button
                onClick={() => handleReceiveClick(cell.row.original)}
                size="sm"
                type="button"
                variant="primary"
              >
                Receive
              </Button>
            </div>
          ) : null,
        Header: "Receive Mouthpiece",
      },
      {
        accessor: "tracking_number",
        Filter: DefaultColumnFilter,
        filter: "exactText",
        Cell: ({ cell }) => {
          const trackingNumber = cell.row.original.tracking_number;
          if (trackingNumber && trackingNumber.length === 10) {
            return (
              <a
                href={`https://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {trackingNumber}
              </a>
            );
          } else if (trackingNumber && trackingNumber.length === 12) {
            return (
              <a
                href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {trackingNumber}
              </a>
            );
          } else if (trackingNumber) {
            return trackingNumber;
          } else {
            return null;
          }
        },
        Header: "Tracking",
      },
      {
        accessor: "order_number",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Order",
        // show: false,
      },
      {
        accessor: "mouthpiece_serial_number",
        Header: "Serial",
      },
      {
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Status",
      },
      {
        Header: "Requested By",
        accessor: "requested_delivery_date",
        Filter: NoColumnFilter,
        filter: "exactText",
        Cell: ({ cell }) =>
          cell.row.original.requested_delivery_date ? (
            <div>
              {addOneDayOrPushToMonday(
                cell.row.original.requested_delivery_date
              )}
            </div>
          ) : null,
      },
      {
        accessor: (d) => `${d.last_name}, ${d.first_name}`,
        Header: "Name",
        id: "name",
      },
      {
        accessor: "ngs_position",
        Header: "Position",
      },
      {
        accessor: "roster_number",
        Header: "Roster",
      },
      {
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "Type",
      },
      {
        accessor: "ddrm_serial_number",
        Filter: SelectColumnFilter,
        filter: "exactText",
        Header: "DDRM",
        show: false,
      },
    ],
    []
  );

  return (
    <>
      {teamOrders.length ? (
        <>
          <div className="">
            <Table
              className="mb-0"
              columns={columns}
              data={teamOrders}
              key="teamOrdersTable"
            />
          </div>
          <Modal
            animation={false}
            background="static"
            centered
            onHide={() => handleModalClose()}
            show={show}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Confirm Receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {row
                ? `Receive mouthpiece ${row.mouthpiece_serial_number} for ${row.first_name} ${row.last_name}, #${row.roster_number}?`
                : null}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => handleModalClose()}
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleConfirmClick()}
                type="button"
                variant="danger"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
}
