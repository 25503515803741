import Alert from "react-bootstrap/Alert";
import { Auth } from "aws-amplify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

export default function SignInForm({ setChallenge, setUnconfirmedUser }) {
  const [alert, setAlert] = useState(null);

  const formik = useFormik({
    initialValues: {
      password: "",
      username: "",
    },
    onSubmit: (values) => {
      Auth.signIn(values.username, values.password)
        .then((user) => {
          if (alert) setAlert(null);
          setChallenge(user.challengeName);
          setUnconfirmedUser(user);
        })
        .catch((err) => {
          switch (err.code) {
            case "UserNotFoundException":
              setAlert(err.message);
              break;
            case "NotAuthorizedException":
              setAlert(err.message);
              break;
            default:
              setAlert("Something went horribly wrong...");
          }
        });
    },
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(12, "Password must be at least 12 characters.")
        .required("Password is required."),
      username: yup.string().email().required("Username is required."),
    }),
  });

  return (
    <div className="align-items-center d-flex full-screen justify-content-center">
      <div>
        {alert ? (
          <div className="d-flex justify-content-center mb-4">
            <Alert variant="danger">{alert}</Alert>
          </div>
        ) : null}
        <div className="d-flex justify-content-center">
          <Form
            className="centered-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.username && formik.touched.username}
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.password && formik.touched.password}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Button size="lg" className="mt-4" type="submit" variant="primary">
              Sign In
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
