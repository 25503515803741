import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentOrgState,
  showNicknameModalState,
} from "components/shared/States";

export default function EditNicknameModal({ playerDetails, playerDetailsId }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [showNicknameModal, setShowNicknameModal] = useRecoilState(
    showNicknameModalState
  );
  const currentOrg = useRecoilValue(currentOrgState);

  const initialValues = {
    mgs_player_id: playerDetailsId,
    nickname: playerDetails.nickname ? playerDetails.nickname : "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        mgs_player_id: playerDetailsId,
        nickname: values.nickname,
      };

      axios
        .put("players", body)
        .then(() => {
          mutate(`mouthpieces/player/${playerDetailsId}`);
          mutate(`players/${currentOrg}`).then(() => {
            setShowNicknameModal(false);
            setDisabled(false);
            setLoading(false);
            setErrored(false);
          });
        })
        .catch((error) => {
          console.log(error.response);
          setErrored(true);
          setLoading(false);
          setDisabled(false);
        });
    },
    validate: function (values) {
      values.nickname = values.nickname.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      setLoading(false);
      setErrored(false);

      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      nickname: yup
        .string()
        .max(50, "Nicknames must be at most 50 characters."),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => {
        formik.resetForm();
        setLoading(false);
        setErrored(false);
      }}
      show={showNicknameModal}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${playerDetails.first_name} ${playerDetails.last_name} Nickname`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!formik.errors.nickname && formik.touched.nickname}
                name="nickname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nickname}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.nickname}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              formik.resetForm();
              setShowNicknameModal(false);
              setLoading(false);
              setErrored(false);
            }}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update
            {loading === true ? (
              <>
                <span style={{ marginLeft: "2%" }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                  <span className="visually-hidden">Loading...</span>
                </span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
