import React, { Suspense } from "react";
import ErrorBoundary from "components/shared/ErrorBoundary";
import Spinner from "components/shared/Spinner";
import PlayersAddTable from "./components/PlayersAddTable";

export default function PlayersAdd() {
  return (
    <>
      <h3 className="mb-4">Add Players</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<Spinner className="full-screen" />}>
          <PlayersAddTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
