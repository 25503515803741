import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import LIOForm from "./LIOForm";
import { MultiSelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function LIOTable() {
  const [organization, setOrganization] = useState(null);
  const [serials, setSerials] = useState(null);
  const [show, setShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessor: "organization",
        Header: "Organization",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
      },
      {
        accessor: (d) => `${d.last_name}, ${d.first_name}`,
        Header: "Name",
        id: "name",
      },
      {
        accessor: "ngs_position",
        Filter: MultiSelectColumnFilter,
        filter: "multiple",
        Header: "Organization",
        width: 100,
      },
      {
        accessor: "roster_number",
        Header: "Roster",
      },
      {
        Cell: ({ cell }) =>
          cell.row.original.mouthpieces_available.length > 0 ? (
            <LIOForm
              clickHandler={handleShipClick}
              mouthpieces={cell.row.original.mouthpieces_available}
              organization={cell.row.original.organization}
            />
          ) : (
            "None Available"
          ),
        Header: "Ship Replacement",
      },
    ],
    []
  );

  const { data: players } = useSWR("mouthpieces/low-inventory/organizations");

  const handleConfirmClick = () => {
    axios
      .put("ddrms", {
        serial_number: serials.ddrm,
        location: organization,
      })
      .then(() => {
        mutate("mouthpieces/low-inventory/organizations");
        mutate("mouthpieces/low-inventory/technical-support");
        handleModalClose();
      })
      .catch((err) => console.log(err));
  };

  const handleModalClose = () => {
    setOrganization(null);
    setSerials(null);
    setShow(false);
  };

  const handleShipClick = (organization, stringifiedSerials) => {
    setOrganization(organization);
    setSerials(JSON.parse(stringifiedSerials));
    setShow(true);
  };

  return (
    <>
      <Table className="half-screen" columns={columns} data={players} />
      <Modal
        animation={false}
        background="static"
        centered
        onHide={() => handleModalClose()}
        show={show}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Confirm Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {serials
            ? `Ship mouthpiece ${serials.mouthpiece} to ${organization}?`
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleModalClose()}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmClick()}
            type="button"
            variant="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
