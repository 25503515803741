import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { mutate } from "swr";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentOrgState,
  ddrmSerialNumberState,
  faultReasonState,
  playerDetailsIdState,
  showResolveFaultsModalState,
} from "components/shared/States";

export default function ResolveFaultsModal() {
  const currentOrg = useRecoilValue(currentOrgState);
  const playerDetailsId = useRecoilValue(playerDetailsIdState);
  const [faultReason, setFaultReason] = useRecoilState(faultReasonState);
  const [showResolveFaultsModal, setShowResolveFaultsModal] = useRecoilState(
    showResolveFaultsModalState
  );
  const [ddrmSerialNumber, setDdrmSerialNumber] = useRecoilState(
    ddrmSerialNumberState
  );

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const formInitialValues = {
    ddrm_serial_number: ddrmSerialNumber,
    status: "NEW",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        ddrm_serial_number: ddrmSerialNumber,
        status: "RESOLVED",
      };

      const body2 = {
        serial_number: ddrmSerialNumber,
        functional: false,
      };

      const requests = [];
      requests.push(axios.put("faults/ddrm", body));
      requests.push(axios.put("ddrms", body2));

      axios
        .all(requests)
        .then(() => {
          mutate(`mouthpieces/player/${playerDetailsId}`);
          mutate(`players/${currentOrg}`);
          setDisabled(false);
          setLoading(false);
          setErrored(false);
          setFaultReason("");
          setDdrmSerialNumber(null);
          setShowResolveFaultsModal(false);
        })
        .catch((error) => {
          console.log("faults/ddrm error", error.response);
          setLoading(false);
          setErrored(true);
          setDisabled(false);
        });
    },
  });

  let reasons = [];
  let result = faultReason.indexOf(",");
  if (result === -1) {
    reasons.push(faultReason);
  } else {
    reasons = faultReason.split(",");
  }

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setShowResolveFaultsModal(false)}
      show={showResolveFaultsModal}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Mouthpiece Fault{reasons.length > 1 && "s"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-0">
            <Col>
              <p className="mb-2">
                <strong>
                  {reasons.length > 1
                    ? "This mouthpiece has multiple faults"
                    : "This mouthpiece has a fault"}
                  :
                  <br />
                  <span className="text-danger">
                    {reasons.length === 1 && <span>{reasons}</span>}
                  </span>
                </strong>
              </p>
              {reasons.length > 1 ? (
                <ul>
                  {reasons.map((reason, index) => (
                    <li key={index} className="text-danger fw-bold">
                      {reason}
                    </li>
                  ))}
                </ul>
              ) : null}
              <p className="mb-2">
                If you have a replacement mouthpiece at your location, we
                recommend that you use that mouthpiece and deactivate this one.
                If you do not yet have a replacement, we would ask that you
                continue to use this mouthpiece until a replacement arrives.
                Deactivating this mouthpiece will mark the electronics for this
                device as non-functional.
              </p>
              <p className="mb-0">
                <strong>
                  Are you sure you want to deactivate this mouthpiece?
                </strong>
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowResolveFaultsModal(false);
              setDisabled(false);
              setErrored(false);
              setFaultReason("");
            }}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type="submit"
            variant="danger"
            className="d-table"
          >
            <span className="table-row">
              <span className="table-cell">Deactivate</span>
              {loading === true ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                  <span className="visually-hidden">Loading...</span>
                </>
              ) : (
                ""
              )}
              {errored === true ? (
                <span className="errored btn-label table-cell">
                  <img
                    src="/img/icn-error-white.svg"
                    alt="errored..."
                    style={{ width: "20px", marginLeft: "2%" }}
                  />
                </span>
              ) : (
                ""
              )}
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
