import React, { Suspense } from "react";

import ErrorBoundary from "components/shared/ErrorBoundary";
import FaultsTable from "./components/FaultsTable";
import LoadingSpinner from "components/shared/Spinner";

export default function Faults() {
  return (
    <>
      <h3 className="mb-4">Faults</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <FaultsTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
