import isWeekend from "date-fns/isWeekend";
import nextMonday from "date-fns/nextMonday";
import format from "date-fns/format";
import addDays from "date-fns/addDays";

/**
 * Creates an unordered list of data validation errors to show the user
 * @param errors
 * @returns {string}
 */
export const buildErrorsList = (errors) => {
  let errorsText = "";
  errors.forEach((error) => {
    errorsText =
      errorsText +
      '<li class="pb-2 pt-2 ms-2 me-2 d-block text-left" ' +
      'style="border-bottom: 1px solid #e4e7eb; color: darkred;">' +
      error +
      "</li>";
  });

  return errorsText;
};

/**
 * Gets a random image to render for players based on position
 * @param position
 * @returns {string}
 */
export const getRandomImageByPosition = (position) => {
  switch (position) {
    case "RB":
      return "/img/random/rb.png";
    case "K":
      return "/img/random/kicker.png";
    case "DL":
      return "/img/random/oldl.png";
    case "OL":
      return "/img/random/oldl.png";
    case "WR":
      return "/img/random/wr.png";
    case "QB":
      return "/img/random/qb.png";
    default:
      return "/img/random/default.png";
  }
};

/**
 * Gets the pretty name of mouthpiece color
 * @param color
 * @returns {*}
 */
export const getPrettyMouthpieceColor = (color) => {
  let prettyColor = mouthpieceColors.find((x) => x.uglyColor === color);
  return prettyColor.prettyColor;
};

/**
 * Converts strings to be URL friendly
 * used for CSS classes in this case
 * @param text
 * @returns {string}
 */
export const slugify = (text) => {
  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "") // Trim - from end of text
  );
};

/**
 * Array containing the mouthpiece colors and their pretty names
 * @type {[{prettyColor: string, uglyColor: string},{prettyColor: string, uglyColor: string},{prettyColor: string, uglyColor: string},{prettyColor: string, uglyColor: string},{prettyColor: string, uglyColor: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]}
 */
export const mouthpieceColors = [
  {
    uglyColor: "ABA",
    // prettyColor: "Onyx",
    prettyColor: "Black",
  },
  {
    uglyColor: "ACA",
    // prettyColor: "Crusoe",
    prettyColor: "Dark Green",
  },
  {
    uglyColor: "ADA",
    // prettyColor: "Gulf Blue",
    prettyColor: "Dark Blue",
  },
  {
    uglyColor: "AEA",
    // prettyColor: "Saphire",
    prettyColor: "Blue",
  },
  {
    uglyColor: "AFA",
    // prettyColor: "Navy Blue",
    prettyColor: "Bright Blue",
  },
  {
    uglyColor: "AGA",
    // prettyColor: "Spanish Bistro",
    prettyColor: "Dark Gold",
  },
  {
    uglyColor: "AHA",
    // prettyColor: "Apple",
    prettyColor: "Neon Green",
  },
  {
    uglyColor: "AJA",
    // prettyColor: "Deep Oak",
    prettyColor: "Brown",
  },
  {
    uglyColor: "AKA",
    // prettyColor: "Bitter Lemon",
    prettyColor: "Neon Yellow",
  },
  {
    uglyColor: "ALA",
    // prettyColor: "Cyber Grape",
    prettyColor: "Purple",
  },
  {
    uglyColor: "AMA",
    // prettyColor: "Buccaneer",
    prettyColor: "Maroon",
  },
  {
    uglyColor: "APA",
    // prettyColor: "Tall Poppy",
    // prettyColor: "Desire",
    prettyColor: "Neon Pink",
  },
  {
    uglyColor: "ARA",
    // prettyColor: "Thunderbird",
    prettyColor: "Red",
  },
  {
    uglyColor: "ASA",
    // prettyColor: "Friar Gray",
    prettyColor: "Silver",
  },
  {
    uglyColor: "ATA",
    // prettyColor: "Acapulco",
    prettyColor: "Light Turquoise",
  },
  {
    uglyColor: "AUA",
    // prettyColor: "Sahara",
    prettyColor: "Yellow Gold",
  },
  {
    uglyColor: "AVA",
    // prettyColor: "Sea Green",
    prettyColor: "Green",
  },
  {
    uglyColor: "AWA",
    // prettyColor: "Tiara",
    prettyColor: "White",
  },
  {
    uglyColor: "AXA",
    // prettyColor: "Flamingo",
    prettyColor: "Orange",
  },
  {
    uglyColor: "AYA",
    // prettyColor: "Bird Flower",
    prettyColor: "Yellow",
  },
  {
    uglyColor: "AAA",
    prettyColor: "Transparent",
  },
];

/**
 * Generates the target delivery date based on the number of days avoiding weekends
 * @param startDate
 * @param numberOfDays
 * @returns {string}
 */
export function targetDeliveryDate(startDate, numberOfDays) {
  for (let i = 1; i <= numberOfDays; i++) {
    startDate.setDate(startDate.getDate() + 1);
    if (startDate.getDay() === 6) {
      startDate.setDate(startDate.getDate() + 2);
    } else if (startDate.getDay() === 0) {
      startDate.setDate(startDate.getDate() + 1);
    }
  }

  const targetDeliveryDate = startDate.toISOString().split("T")[0];

  return targetDeliveryDate;
}

export const addOneDayOrPushToMonday = (date) => {
  let formatDate = new Date(date);

  if (isWeekend(formatDate)) {
    let nextMon = nextMonday(formatDate);
    return format(nextMon, "yyyy-MM-dd");
  } else {
    // add 2 days to the date because it assumes date is zulu time
    let newDate = addDays(formatDate, 2);
    if (isWeekend(newDate)) {
      let nexMon = nextMonday(newDate);
      return format(nexMon, "yyyy-MM-dd");
    } else {
      return format(newDate, "yyyy-MM-dd");
    }
  }
};
