import React from "react";
import { Redirect, Route } from "react-router-dom";

import DDRCs from "components/views/ddrcs/DDRCs";
import DDRMs from "components/views/ddrms/DDRMs";
import Faults from "components/views/faults/Faults";
import Landing from "components/views/landing/Landing";
import Team from "components/views/team/Team";
import Mouthpieces from "components/views/mouthpieces/Mouthpieces";
import MouthpiecesOrder from "components/views/mouthpieces/MouthpiecesOrder";
import MouthpiecesPlayerId from "components/views/mouthpieces/MouthpiecesPlayerId";
import Organizations from "components/views/organizations/Organizations";
import Players from "components/views/players/Players";
import PlayersAdd from "components/views/players/PlayersAdd";
import SignIn from "components/views/sign-in/SignIn";
import Redoc from "components/views/documentation/Redoc";
import * as PropTypes from "prop-types";

function Suspense(props) {
  return null;
}

Suspense.propTypes = {
  fallback: PropTypes.element,
  children: PropTypes.node,
};
export default function Routes({ group, setGroup }) {
  // const requestPath = window.location.pathname;
  // console.log( "requestPath", requestPath );

  switch (group) {
    case "biocore":
      return (
        <>
          <Route exact path="/">
            <Team />
          </Route>
          <Route exact path="/ddrcs">
            <DDRCs />
          </Route>
          <Route exact path="/ddrms">
            <DDRMs />
          </Route>
          <Route exact path="/faults">
            <Faults />
          </Route>
          <Route exact path="/mouthpieces">
            <Mouthpieces />
          </Route>
          <Route exact path="/mouthpieces/order">
            <MouthpiecesOrder />
          </Route>
          <Route path="/mouthpieces/player/id/:id">
            <MouthpiecesPlayerId />
          </Route>
          <Route exact path="/organizations">
            <Organizations />
          </Route>
          <Route exact path="/players">
            <Players />
          </Route>
          <Route exact path="/players/add">
            <PlayersAdd />
          </Route>
          <Route exact path="/landing">
            <Landing />
          </Route>
          <Route exact path="/documentation/redoc">
            <Redoc />
          </Route>
          {/*<Route exact path="/team">*/}
          {/*  <Team />*/}
          {/*</Route>*/}
          <Route>
            <Redirect to="/" />
          </Route>
        </>
      );
    case "team":
      return (
        <>
          <Route exact path="/">
            <Team />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </>
      );
    default:
      return (
        <>
          <Route exact path="/signin">
            <SignIn setGroup={setGroup} />
          </Route>
          <Route>
            <Redirect to="/signin" />
          </Route>
        </>
      );
  }
}
