import React, { Suspense } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ErrorBoundary from "components/shared/ErrorBoundary";
import LoadingSpinner from "components/shared/Spinner";
import CriticalFaultsTable from "./components/CriticalFaultsTable";
import LIOTable from "./components/LIOTable";
import LITSTable from "./components/LITSTable";
import OrderedTable from "./components/OrderedTable";
import SummaryTable from "./components/SummaryTable";
import config from "config.json";

export default function Landing() {
  const tables = [
    {
      component: <CriticalFaultsTable />,
      key: "critical-faults",
      title: "Critical Faults",
    },
    {
      component: <LIOTable />,
      key: "lio",
      title: "Low Inventory at Organizations",
    },
    {
      component: <LITSTable />,
      key: "lits",
      title: "Low Inventory at Tech Support",
    },
    {
      component: <OrderedTable />,
      key: "ordered",
      title: "Ordered",
    },
  ];

  return (
    <>
      <h3 className="mb-4">Action Items</h3>
      <Tabs transition={false}>
        {tables.map((table) => {
          const { component, key, title } = table;
          return (
            <Tab eventKey={key} key={key} title={title}>
              <ErrorBoundary className="half-screen">
                <Suspense fallback={<LoadingSpinner className="half-screen" />}>
                  {component}
                </Suspense>
              </ErrorBoundary>
            </Tab>
          );
        })}
      </Tabs>
      <br />
      <h3 className="mb-4">Summary</h3>
      <Tabs transition={false}>
        {config.activeOrganizations.map((organization) => (
          <Tab eventKey={organization} key={organization} title={organization}>
            <ErrorBoundary className="half-screen">
              <Suspense fallback={<LoadingSpinner className="half-screen" />}>
                <SummaryTable organization={organization} />
              </Suspense>
            </ErrorBoundary>
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
