import React, { Suspense } from "react";
import ErrorBoundary from "components/shared/ErrorBoundary";
import LoadingSpinner from "components/shared/Spinner";
import PlayersTable from "./components/PlayersTable";

export default function Players() {
  return (
    <>
      <h3 className="mb-4">Players</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <PlayersTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
