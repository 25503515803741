import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import { SelectColumnFilter } from "../../../../utils/filters";
import Table from "../../../shared/Table";

export default function LITSTable() {
  const [row, setRow] = useState(null);
  const [show, setShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: "organization",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Organization",
          },
          {
            accessor: (d) => `${d.last_name}, ${d.first_name}`,
            Header: "Name",
            id: "name",
          },
          {
            accessor: "ngs_position",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Position",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
        ],
        Header: "Player",
      },
      {
        columns: [
          {
            Cell: ({ cell }) =>
              cell.row.original.mouthpiece_order ? (
                <Button
                  onClick={() => handleOrderClick(cell.row.original)}
                  size="sm"
                  type="button"
                  variant="danger"
                >
                  Order
                </Button>
              ) : (
                "Unable to replicate previous order."
              ),
            Header: "Order Replacement",
          },
        ],
        Header: "Action",
      },
    ],
    []
  );

  const { data: players } = useSWR(
    "mouthpieces/low-inventory/technical-support"
  );

  const handleConfirmClick = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    const order = {
      ...row.mouthpiece_order,
      status: "Approved",
      requested_delivery_date: `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`,
    };
    order.dentition_quantity = 0;
    order.recipient = "ZEB";
    axios
      .post("mouthpieces", order)
      .then(() => {
        mutate("mouthpieces/low-inventory/technical-support");
        mutate("mouthpieces/ordered");
        handleModalClose();
      })
      .catch((err) => console.log(err));
  };
  const handleModalClose = () => {
    setRow(null);
    setShow(false);
  };
  const handleOrderClick = (row) => {
    setRow(row);
    setShow(true);
  };

  return (
    <>
      <Table className="half-screen" columns={columns} data={players} />
      <Modal
        animation={false}
        background="static"
        centered
        onHide={() => handleModalClose()}
        show={show}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Confirm Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {row
            ? `Order mouthpiece for ${row.last_name}, ${row.first_name} of ${row.organization}?`
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleModalClose()}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmClick()}
            type="button"
            variant="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
