import BootstrapTable from "react-bootstrap/Table";
import React, { useMemo } from "react";
import { useFilters, useTable, useSortBy, usePagination } from "react-table";
import { DefaultColumnFilter } from "utils/filters";
import "./Table.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecoilState } from "recoil";
import { tablePageIndexState } from "components/shared/States";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

export default function Table({
  className,
  columns,
  data,
  initialState,
  onClick,
}) {
  const [tablePageIndex, setTablePageIndex] = useRecoilState(
    tablePageIndexState
  );

  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

  const filterTypes = React.useMemo(
    () => ({
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
    }),
    []
  );

  let initialStateObject = {
    pageIndex: tablePageIndex,
    pageSize: 25,
    hiddenColumns: columns
      .filter((col) => col.show === false)
      .map((col) => col.accessor),
    ...initialState,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetFilters: false,
      autoResetSortBy: false,
      columns,
      data,
      defaultColumn,
      initialState: initialStateObject,
      filterTypes,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  /**
   * reset the page to 0 if the user filters the table
   * and the current page is higher than the pageCount
   *
   * we can add other items in here such as pageIndex, pageSize,
   * sortBy, filters, etc. if needed to do other adjustments as well
   * @param pageCount
   */
  // eslint-disable-next-line
  const onFetchData = ({ pageCount }) => {
    if (pageCount < tablePageIndex) {
      setTablePageIndex(0);
      gotoPage(0);
    }
  };

  /**
   * When changes are made, check if we need to make changes with the
   * onFetchData method. we can add the same types of dependencies and information
   * here to effect changes in onFetchData
   */
  React.useEffect(() => {
    onFetchData({ pageCount });
  }, [onFetchData, pageCount]);

  return data.length > 0 ? (
    <div className={`fixed-header ${className}`}>
      <BootstrapTable
        {...getTableProps()}
        bordered
        className="text-center"
        size="sm"
        striped
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="position-relative"
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="pt-2"
                  style={{ borderBottom: "0 solid" }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <span style={{ cursor: "pointer" }}>
                    {column.render("Header")}
                    <span className="ps-2">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            color="gray"
                            icon={faChevronCircleDown}
                          />
                        ) : (
                          <FontAwesomeIcon
                            color="gray"
                            icon={faChevronCircleUp}
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="position-relative"
            >
              {headerGroup.headers.map((column, index) => (
                <th key={index}>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    className={index === 0 && onClick ? "editable-cell" : null}
                    onClick={
                      index === 0 && onClick
                        ? () => onClick(row.original)
                        : null
                    }
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
      {data.length > 25 ? (
        <div className="pagination">
          <button
            onClick={() => {
              gotoPage(0);
              setTablePageIndex(0);
            }}
            disabled={!canPreviousPage}
            className={"btn btn-primary me-1"}
          >
            {"<<"}
          </button>{" "}
          <button
            onClick={() => {
              previousPage();
              setTablePageIndex(pageIndex - 1);
            }}
            disabled={!canPreviousPage}
            className={"btn btn-primary me-2"}
          >
            {"<"}
          </button>{" "}
          <button
            onClick={() => {
              nextPage();
              setTablePageIndex(pageIndex + 1);
            }}
            disabled={!canNextPage}
            className={"btn btn-primary me-1"}
          >
            {">"}
          </button>{" "}
          <button
            onClick={() => {
              gotoPage(pageCount - 1);
              setTablePageIndex(pageCount - 1);
            }}
            disabled={!canNextPage}
            className={"btn btn-primary me-2"}
          >
            {">>"}
          </button>{" "}
          <span className={"pt-2 me-2"}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span className={"me-2"}>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
                setTablePageIndex(page);
              }}
              style={{ width: "80px", height: "38px", padding: "2px 8px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 25, 50, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    <div
      className={`align-items-center d-flex justify-content-center ${className}`}
    >
      <h4>
        <FontAwesomeIcon color="dodgerblue" icon={faInfoCircle} />
        <span className="ms-2">No data to display.</span>
      </h4>
    </div>
  );
}
