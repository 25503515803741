import { Auth } from "aws-amplify";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { cache, SWRConfig } from "swr";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import React, { Suspense, useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { RecoilRoot } from "recoil";
// import { DebugObserver } from "components/shared/Debug";
import Navigation from "./components/Navigation";
import Routes from "./components/Routes";
import config from "config";

import "./App.scss";
import LoadingSpinner from "components/shared/Spinner";
import ErrorBoundary from "components/shared/ErrorBoundary";

export default function App() {
  const [group, setGroup] = useState("");
  const [cogTeam, setCogTeam] = useState("");
  const [cogUser, setCogUser] = useState("");

  useEffect(() => {
    if (!group) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          setCogUser(user);
          setCogTeam(user.attributes["custom:Teams"]);

          const groups =
            user.signInUserSession.accessToken.payload["cognito:groups"];

          if (groups.find((group) => /team/.test(group))) {
            setGroup("team");
          } else if (groups.find((group) => /biocore/.test(group))) {
            setGroup("biocore");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
      axios.interceptors.request.use(function (config) {
        return new Promise((resolve) => {
          Auth.currentSession()
            .then((session) => {
              if (!cogTeam) {
                setCogTeam(session.idToken.payload["custom:Teams"]);
              }

              const current = Math.floor(new Date() / 1000);
              const expiration = session.getIdToken().getExpiration();
              if (current > expiration) {
                Auth.currentAuthenticatedUser().then((user) => {
                  if (!cogUser) {
                    setCogUser(user);
                  }

                  if (!cogTeam) {
                    setCogTeam(user.attributes["custom:Teams"]);
                  }

                  user.refreshSession(
                    session.getRefreshToken(),
                    (error, refreshedSession) => {
                      if (error) {
                        console.log(error);
                        handleSignOut();
                      } else {
                        config.headers.Authorization = `Bearer ${refreshedSession
                          .getIdToken()
                          .getJwtToken()}`;
                        resolve(config);
                      }
                    }
                  );
                });
              } else {
                config.headers.Authorization = `Bearer ${session
                  .getIdToken()
                  .getJwtToken()}`;
                resolve(config);
              }
            })
            .catch((error) => {
              console.log(error);
              handleSignOut();
            });
        });
      });
    }
  }, [group, cogUser, cogTeam]);

  window.addEventListener("beforeunload", () => cache.clear());

  const fetcher = (endpoint) =>
    axios.get(endpoint).then((result) => result.data);

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        cache.clear();
        setGroup("");
        setCogTeam("");
      })
      .catch((error) => console.log(error));
  };

  const today = new Date();
  const currentYear = today.getFullYear();

  return (
    <RecoilRoot>
      {/*<DebugObserver />*/}
      <SWRConfig
        value={{
          fetcher,
          revalidateOnFocus: false,
          suspense: true,
          revalidateOnMount: true,
          revalidateIfStale: true,
          refreshInterval: config.swrRefreshInterval,
        }}
      >
        <BrowserRouter>
          <Helmet>
            <script
              type="text/javascript"
              async={"async"}
              src={"https://widget.bugyard.io/bugyard.min.js"}
              defer={"defer"}
              data-bugyard={"61f19918c4280e0014c89f18"}
            />
          </Helmet>
          <header>
            <ErrorBoundary className="quarter-screen">
              <Suspense
                fallback={<LoadingSpinner className="quarter-screen" />}
              >
                <Navbar className="justify-content-between px-4" variant="dark">
                  <Navigation
                    group={group}
                    handleSignOut={handleSignOut}
                    cogTeam={cogTeam}
                  />
                </Navbar>
              </Suspense>
            </ErrorBoundary>
          </header>
          <main>
            <Container className="main-container" fluid>
              <Switch>
                <Routes group={group} setGroup={setGroup} />
              </Switch>
            </Container>
          </main>
          <footer>
            <div className="align-items-center d-flex justify-content-center">
              © {currentYear} Biomechanics Consulting and Research, LC. All
              rights reserved.
            </div>
          </footer>
        </BrowserRouter>
      </SWRConfig>
    </RecoilRoot>
  );
}
