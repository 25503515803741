import React, { useEffect } from "react";
import useSWR from "swr";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
  mouthpiecesPresentTableDataState,
  playerDetailsIdState,
  playerDetailsIndicatorPositionState,
  playerDetailsState,
  playerMouthpiecesState,
  // playerOrdersCountState,
  // showDeactivatePlayerModalState,
  showNicknameModalState,
  showOrderMouthguardModalState,
  showPlayerDetailsState,
} from "components/shared/States";
import CasePosition from "components/views/team/components/activePlayers/details/CasePosition";
import OrderMouthpieceModal from "components/views/team/components/activePlayers/details/OrderMouthpieceModal";
import EditNicknameModal from "components/views/team/components/activePlayers/details/EditNicknameModal";
import DeactivatePlayerModal from "components/views/team/components/activePlayers/details/DeactivatePlayerModal";
import PlayerOrdersTable from "components/views/team/components/activePlayers/details/PlayerOrdersTable";
import PlayerMouthpiecesTable from "components/views/team/components/activePlayers/details/PlayerMouthpiecesTable";

export default function PlayerDetails({ nickname }) {
  // const [disabled, setDisabled] = useRecoilState(playerOrdersCountState);

  // Recoil states
  const playerDetailsId = useRecoilValue(playerDetailsIdState);
  const showPlayerDetails = useRecoilValue(showPlayerDetailsState);
  const playerDetails = useRecoilValue(playerDetailsState);
  const setPlayerMouthpieces = useSetRecoilState(playerMouthpiecesState);

  const mouthpiecesPresentTableData = useRecoilValue(
    mouthpiecesPresentTableDataState
  );
  const playerDetailsIndicatorPosition = useRecoilValue(
    playerDetailsIndicatorPositionState
  );
  // const setShowDeactivatePlayerModal = useSetRecoilState(
  //   showDeactivatePlayerModalState
  // );
  const [showNicknameModal, setShowNicknameModal] = useRecoilState(
    showNicknameModalState
  );
  const [
    showOrderMouthguardModal,
    setShowOrderMouthguardModal,
  ] = useRecoilState(showOrderMouthguardModalState);

  /*************** DATA LOAD AND LAYOUT SETUP *************/
  // data for mouthpieces table
  const { data: playerMouthpiecesData } = useSWR(
    `mouthpieces/player/${playerDetailsId}`
  );

  // data for orders table
  const { data: orders } = useSWR(
    `mouthpieces/ordered/player/${playerDetailsId}`
  );

  // data for last order
  const { data: lastOrder } = useSWR(
    `mouthpieces/player/recent/${playerDetailsId}`
  );

  // disable the order button if the user has more than 3 orders
  const functionalMouthpieces = playerDetails.mouthpiece_counts.functional;
  const totalMouthPiecesForPlayer = orders.length + functionalMouthpieces;

  useEffect(() => {
    setPlayerMouthpieces(playerMouthpiecesData.mouthpieces);

    // temporarily comment out in case we want to come back to limiting order numbers
    // if (totalMouthPiecesForPlayer > 2) {
    //   setDisabled(true);
    // } else {
    //   setDisabled(false);
    // }
  });

  // controls layout of triangle indicator for which player is selected
  const style = {
    left: playerDetailsIndicatorPosition + "px",
  };

  if (playerDetailsId !== 0) {
    // control case position
    let controlCase = 0;
    let casePosition = 0;
    if (playerMouthpiecesData) {
      if (playerMouthpiecesData.case_serial_number) {
        if ("case_serial_number" in playerMouthpiecesData) {
          const controlCaseSerial = playerMouthpiecesData.case_serial_number.split(
            "-"
          );
          controlCase = controlCaseSerial[2].replace("0", "");
        }
        casePosition = playerMouthpiecesData.case_position;
      }
    }

    return (
      <>
        <div
          className={`player-details w-full mb-4 p-4 bg-primary bg-gradient bg-opacity-10 shadow-inset position-relative ${
            showPlayerDetails === true ? "d-block" : "d-none"
          }`}
        >
          {/********** DETAILS TOP BAR *************/}
          <div className="triangle-indicator-wrapper" style={style}>
            <div className="triangle-indicator">&nbsp;</div>
          </div>
          <div className="d-flex flex-row mb-4">
            <CasePosition position={casePosition} case={controlCase} />
            <div className="pt-2 ps-4">
              <div className="pt-3">
                <strong className="me-2">Nickname:</strong>
                {!playerMouthpiecesData.nickname ? (
                  <>&nbsp;{playerMouthpiecesData.first_name}</>
                ) : (
                  <>{`${playerMouthpiecesData.nickname}`}</>
                )}
                <button
                  className="btn btn-text btn-sm mt-n1"
                  onClick={(e) => {
                    setShowNicknameModal(true);
                  }}
                >
                  <img
                    src="/img/icn-edit.svg"
                    width="16"
                    height="16"
                    alt="edit"
                  />
                </button>
              </div>
              <div>
                <strong>
                  #{`${playerMouthpiecesData.roster_number ?? "Unassigned"}`}
                </strong>{" "}
                &nbsp;|&nbsp;
                {playerMouthpiecesData.first_name}{" "}
                {playerMouthpiecesData.last_name}
              </div>
            </div>
            <div className="flex-grow-1">&nbsp;</div>
            <div className="pt-4 pe-2">
              <button
                className={`btn btn-primary`}
                onClick={(e) => {
                  setShowOrderMouthguardModal(true);
                }}
                // temporarily comment out in case we want to come back to limiting order numbers
                // disabled={disabled}
              >
                {/* // temporarily comment out in case we want to come back to limiting order numbers */}
                {/*{disabled*/}
                {/*  ? "Order Mouthpiece (Limit Reached)"*/}
                {/*  : "Order Mouthpiece"}*/}
                Order Mouthpiece
              </button>
            </div>
            {/*<div className="pt-4">*/}
            {/*  <button*/}
            {/*    className="btn btn-secondary"*/}
            {/*    onClick={(e) => {*/}
            {/*      setShowDeactivatePlayerModal(true);*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Deactivate Player*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
          {/********** END DETAILS TOP BAR *************/}

          {/********** MOUTHPIECES TABLE AND MODAL *************/}
          <PlayerMouthpiecesTable
            playerMouthpieces={mouthpiecesPresentTableData}
            playerDetailsId={playerDetailsId}
            playerDetails={playerMouthpiecesData}
          />

          {/********** ORDERS TABLE AND MODAL *************/}
          {orders.length > 0 ? (
            <PlayerOrdersTable
              orders={orders}
              playerDetailsId={playerDetailsId}
            />
          ) : (
            ""
          )}

          {/********** DEACTIVATE PLAYERS MODAL *************/}
          <DeactivatePlayerModal playerDetails={playerMouthpiecesData} />

          {/*********** ORDER MOUTHGUARD MODAL *************/}
          {showOrderMouthguardModal === true ? (
            <OrderMouthpieceModal
              totalMouthPiecesForPlayer={totalMouthPiecesForPlayer}
              lastOrder={lastOrder}
            />
          ) : (
            ""
          )}

          {/********** NICKNAME MODAL *************/}
          {showNicknameModal === true ? (
            <EditNicknameModal
              playerDetails={playerMouthpiecesData}
              playerDetailsId={playerDetailsId}
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  } else {
    return (
      <div
        className={`player-details w-full mb-4 p-4 bg-light shadow-inset position-relative d-none`}
      >
        <div className="triangle-indicator-wrapper" style={style}>
          <div className="triangle-indicator">&nbsp;</div>
        </div>
        <div className="d-flex flex-row mb-4">&nbsp;</div>
      </div>
    );
  }
}
