import React, { Suspense } from "react";

import DDRMsTable from "./components/DDRMsTable";
import ErrorBoundary from "../../shared/ErrorBoundary";
import LoadingSpinner from "../../shared/Spinner";

export default function DDRMs() {
  return (
    <>
      <h3 className="mb-4">DDRMs</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <DDRMsTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
