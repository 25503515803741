import React, { useMemo, useState } from "react";
import useSWR from "swr";

import DDRCsModal from "./DDRCsModal";
import { MultiSelectColumnFilter, SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function DDRCsTable() {
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      // DDRC
      {
        Header: "DDRC",
        columns: [
          {
            accessor: "serial_number",
            Header: "Serial",
            Cell: ({ cell }) =>
              cell.row.original.serial_number ? (
                <div className="link">{cell.row.original.serial_number}</div>
              ) : null,
          },
          {
            accessor: (d) => d.mac_address.toUpperCase(),
            Header: "MAC",
            id: "mac_address",
          },
          {
            accessor: "firmware_version",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Firmware",
          },
          {
            accessor: (d) => (d.functional ? "TRUE" : "FALSE"),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Functional",
            id: "functional",
          },
          {
            accessor: "location",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Location",
          },
          {
            accessor: "allocation_type",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Allocation",
          },
          {
            accessor: "comments",
            Header: "Comments",
          },
        ],
      },
      // CASE
      {
        Header: "Case",
        columns: [
          {
            accessor: (d) =>
              d.case_serial_number ? d.case_serial_number : " ",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Serial",
            id: "case_serial_number",
          },
          {
            accessor: (d) => (d.case_position ? d.case_position : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Position",
            id: "case_position",
          },
        ],
      },
      {
        Header: "Player",
        columns: [
          {
            accessor: "mgs_player_id",
            Header: "ID",
          },
          {
            accessor: (d) =>
              d.last_name != null ? d.last_name + ", " + d.first_name : " ",
            Header: "Name",
          },
          {
            accessor: (d) => (d.organization ? d.organization : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Organization",
            id: "organization",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: (d) =>
              d.study_participation
                ? "ACTIVE"
                : d.mgs_player_id
                ? "INACTIVE"
                : " ",
            Header: "Status",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            id: "study_participation",
          },
        ],
      },
    ],
    []
  );

  const { data: ddrcs } = useSWR("ddrcs");
  const { data: organizations } = useSWR("organizations");
  const { data: players } = useSWR("players");

  return (
    <>
      <Table
        className="full-screen ddrc"
        columns={columns}
        data={ddrcs}
        onClick={setRow}
      />
      {row ? (
        <DDRCsModal
          ddrcs={ddrcs}
          organizations={organizations}
          players={players}
          row={row}
          setRow={setRow}
        />
      ) : null}
    </>
  );
}
