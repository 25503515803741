import React from "react";
import MouthpiecesOrderTable from "./components/MouthpiecesOrderTable";

export default function MouthpieceOrder() {
  return (
    <>
      <h3 className="mb-4">Mouthpiece Order</h3>
      <MouthpiecesOrderTable />
    </>
  );
}
