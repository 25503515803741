import React, { useMemo, useState } from "react";
import useSWR from "swr";

import { MultiSelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";
import OrganizationsModal from "./OrganizationsModal";

export default function OrganizationsTable() {
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Organization",
        columns: [
          {
            accessor: "abbreviation",
            Header: "Abbreviation",
            Cell: ({ cell }) =>
              cell.row.original.abbreviation ? (
                <div className="link">{cell.row.original.abbreviation}</div>
              ) : null,
          },
          {
            accessor: "name",
            Header: "Name",
          },
          {
            accessor: (d) => (d.team_type ? d.team_type : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Type",
            id: "team_type",
          },
          {
            accessor: "ngs_team_id",
            Header: "NGS ID",
          },
        ],
      },
      {
        Header: "Contact",
        columns: [
          {
            accessor: "contact_name",
            Header: "Name",
          },
          {
            accessor: "contact_email",
            Header: "Email",
          },
          {
            accessor: "contact_phone",
            Header: "Phone",
          },
          {
            accessor: "contact_address_1",
            Header: "Address",
          },
          {
            accessor: "contact_address_2",
            Header: "Address 2",
          },
          {
            accessor: "contact_city",
            Header: "City",
          },
          {
            accessor: "contact_state",
            Header: "State",
          },
          {
            accessor: "contact_zip_code",
            Header: "Zip",
          },
        ],
      },
      {
        Header: "Shipping",
        columns: [
          {
            accessor: "shipping_name",
            Header: "Name",
          },
          {
            accessor: "shipping_address_1",
            Header: "Address",
          },
          {
            accessor: "shipping_address_2",
            Header: "Address 2",
          },
          {
            accessor: "shipping_city",
            Header: "City",
          },
          {
            accessor: "shipping_state",
            Header: "State",
          },
          {
            accessor: "shipping_zip_code",
            Header: "Zip",
          },
        ],
      },
    ],
    []
  );

  const { data: organizations } = useSWR("organizations");

  return (
    <>
      <Table
        className="full-screen organizations"
        columns={columns}
        data={organizations}
        onClick={setRow}
      />
      {row ? (
        <OrganizationsModal
          organizations={organizations}
          row={row}
          setRow={setRow}
        />
      ) : null}
    </>
  );
}
