import Spinner from "react-bootstrap/Spinner";
import React from "react";

export default function LoadingSpinner({ className }) {
  return (
    <div
      className={`align-items-center d-flex justify-content-center ${className}`}
    >
      <Spinner animation="border" />
    </div>
  );
}
