import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import config from "config.json";

export default function DDRCsModal({
  ddrcs,
  organizations,
  players,
  row,
  setRow,
}) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const initialValues = {
    allocation_type: row.allocation_type,
    firmware_version: row.firmware_version,
    functional: row.functional ? "TRUE" : "FALSE",
    location: row.location,
    comments: row.comments ? row.comments : "",
    case_serial_number: row.case_serial_number ? row.case_serial_number : "",
    case_position: row.case_position ? row.case_position.toString() : "",
    mgs_player_id: row.mgs_player_id ? row.mgs_player_id.toString() : "",
    organization: row.organization ? row.organization : "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        serial_number: row.serial_number,
      };
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "case_position":
            case "mgs_player_id":
              body[key] = values[key] ? parseInt(values[key], 10) : null;
              break;
            case "case_serial_number":
              body[key] = values[key] ? values[key] : null;
              break;
            case "comments":
              body[key] = values[key].trim() ? values[key].trim() : null;
              break;
            case "functional":
              body[key] = values[key].toLowerCase();
              break;
            case "organization":
              break;
            default:
              body[key] = values[key];
          }
        }
      }
      axios
        .put("ddrcs", body)
        .then(() => {
          mutate("ddrcs").then((response) => {
            setRow(null);
            setDisabled(false);
            setLoading(false);
            setErrored(false);
          });
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMsg(error.response);
          setErrored(true);
        });
    },
    validate: function (values) {
      values.comments = values.comments.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      case_position: yup
        .string()
        .test(
          "position_required",
          "Case position is required.",
          function (case_position) {
            return !(this.parent.case_serial_number && !case_position);
          }
        ),
      case_serial_number: yup
        .string()
        .matches(/^mgs-[a-z]{2,4}202[0-9]{1}-(0[1-9]|[1-9][0-9])$/, {
          excludeEmptyString: true,
          message:
            "Case serial numbers should be formatted similarly to mgs-bio2020-01.",
        }),
      comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
      mgs_player_id: yup
        .string()
        .test("id_required", "Player is required.", function (mgs_player_id) {
          return !(this.parent.organization && !mgs_player_id);
        }),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${row.serial_number}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Firmware</Form.Label>
                <Form.Control
                  as="select"
                  name="firmware_version"
                  onChange={formik.handleChange}
                  value={formik.values.firmware_version}
                  className="form-select"
                >
                  {config.ddrcFirmwareVersions.map((version) => (
                    <option key={version}>{version}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Functional</Form.Label>
                <Form.Control
                  as="select"
                  name="functional"
                  onChange={formik.handleChange}
                  value={formik.values.functional}
                  className="form-select"
                >
                  <option value="TRUE">TRUE</option>
                  <option value="FALSE">FALSE</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  className="form-select"
                >
                  {organizations.map((organization) => {
                    const abbreviation = organization.abbreviation;
                    return (
                      <option key={abbreviation} value={abbreviation}>
                        {abbreviation}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Allocation</Form.Label>
                <Form.Control
                  as="select"
                  name="allocation_type"
                  onChange={formik.handleChange}
                  value={formik.values.allocation_type}
                  className="form-select"
                >
                  <option value="NFL_MANUFACTURING">NFL MANUFACTURING</option>
                  <option value="NFL_TESTING">NFL TESTING</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Case Serial</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.case_serial_number &&
                    formik.touched.case_serial_number
                  }
                  name="case_serial_number"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const value = event.target.value;
                    formik.setFieldValue("case_serial_number", value);
                    formik.setFieldValue("case_position", "");
                  }}
                  type="text"
                  value={formik.values.case_serial_number}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.case_serial_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Case Position</Form.Label>
                <Form.Control
                  as="select"
                  isInvalid={
                    !!formik.errors.case_position &&
                    formik.touched.case_position
                  }
                  name="case_position"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.case_position}
                  className="form-select"
                >
                  <option value="" />
                  {formik.values.case_serial_number &&
                  !formik.errors.case_serial_number
                    ? [...Array(20).keys()]
                        .filter((x) => {
                          if (
                            formik.values.case_serial_number ===
                              formik.initialValues.case_serial_number &&
                            (x + 1).toString() ===
                              formik.initialValues.case_position
                          ) {
                            return true;
                          } else {
                            return !ddrcs
                              .map(
                                (ddrc) =>
                                  `${ddrc.case_serial_number}-${ddrc.case_position}`
                              )
                              .includes(
                                `${formik.values.case_serial_number}-${x + 1}`
                              );
                          }
                        })
                        .map((x) => {
                          const value = (x + 1).toString();
                          return (
                            <option
                              key={`case_position-${value}`}
                              value={value}
                            >
                              {value}
                            </option>
                          );
                        })
                    : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.case_position}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  as="select"
                  name="organization"
                  onChange={(event) => {
                    formik.setFieldValue("organization", event.target.value);
                    formik.setFieldValue("mgs_player_id", "");
                  }}
                  value={formik.values.organization}
                  className="form-select"
                >
                  <option value=""></option>
                  {[...new Set(players.map((player) => player.organization))]
                    .sort()
                    .map((organization) => (
                      <option key={organization} value={organization}>
                        {organization}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Player</Form.Label>
                <Form.Control
                  as="select"
                  isInvalid={
                    !!formik.errors.mgs_player_id &&
                    formik.touched.mgs_player_id
                  }
                  name="mgs_player_id"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.mgs_player_id}
                  className="form-select"
                >
                  <option value=""></option>
                  {formik.values.organization
                    ? players
                        .filter((player) => {
                          if (
                            formik.values.organization ===
                              formik.initialValues.organization &&
                            player.mgs_player_id.toString() ===
                              formik.initialValues.mgs_player_id
                          ) {
                            return true;
                          } else {
                            return (
                              player.organization ===
                                formik.values.organization &&
                              !ddrcs
                                .map((ddrc) => ddrc.mgs_player_id)
                                .includes(player.mgs_player_id)
                            );
                          }
                        })
                        .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
                        .map((player) => (
                          <option
                            key={`mgs_player_id-${player.mgs_player_id}`}
                            value={player.mgs_player_id}
                          >
                            {`${player.mgs_player_id} - ${player.last_name}, ${player.first_name} - ${player.roster_number}`}
                          </option>
                        ))
                    : null}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.mgs_player_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.comments}
                name="comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
          {errorMsg ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <Alert variant="danger">{errorMsg}</Alert>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
