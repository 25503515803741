import React, { Suspense } from "react";
import Players from "./Players";
import LoadingSpinner from "components/shared/Spinner";
import ErrorBoundary from "components/shared/ErrorBoundary";
import { cognitoGroupState } from "components/shared/States";
import { useRecoilValue } from "recoil";

export default function Team() {
  const cognitoGroup = useRecoilValue(cognitoGroupState);
  return (
    <>
      {cognitoGroup === "biocore" && <h3 className="mb-4">Teams</h3>}
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <Players />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
