import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import Spinner from "react-bootstrap/Spinner";
import * as yup from "yup";
import { useRecoilValue } from "recoil";
import { currentOrgState } from "components/shared/States";

export default function EditMouthpieceModal({ id, organization, row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const currentOrg = useRecoilValue(currentOrgState);
  const re = new RegExp("^(LOST|BIO|" + organization + ")$");

  const initialValues = {
    ddrm_functional: row.ddrm_functional ? "True" : "False",
    mouthpiece_functional: row.mouthpiece_functional ? "True" : "False",
    location: row.location ? row.location : "",
    comments: row.ddrm_comments ? row.ddrm_comments : "",
    mouthpiece_comments: row.mouthpiece_comments ? row.mouthpiece_comments : "",
    serial_number: row.ddrm_serial_number,
    order_number: row.order_number,
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const ddrm = {};
      const mouthpiece = {};

      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "comments":
              ddrm[key] = values[key].trim() ? values[key].trim() : null;
              break;
            case "location":
              ddrm[key] = values[key] ? values[key] : null;
              break;
            case "ddrm_functional":
              ddrm["functional"] = values.ddrm_functional
                ? JSON.parse(values.ddrm_functional.trim().toLowerCase())
                : null;
              break;
            case "serial_number":
              ddrm["serial_number"] = row.ddrm_serial_number;
              break;
            case "mouthpiece_serial_number":
              mouthpiece["serial_number"] = row.mouthpiece_serial_number;
              break;
            case "mouthpiece_comments":
              mouthpiece["comments"] = values[key].trim()
                ? values[key].trim()
                : null;
              break;
            case "mouthpiece_functional":
              mouthpiece["functional"] = values.mouthpiece_functional
                ? JSON.parse(values.mouthpiece_functional.trim().toLowerCase())
                : null;
              break;

            default:
              ddrm[key] = values[key];
          }
        }
      }

      // console.log("ddrm", ddrm);
      // console.log("mouthpiece", mouthpiece);

      const requests = [];
      if (Object.keys(ddrm).length) {
        requests.push(
          axios
            .put("ddrms", {
              serial_number: row.ddrm_serial_number,
              ...ddrm,
            })
            .then((response) => {
              // console.log("ddrms results", response);
              mutate(`mouthpieces/player/${id}`);
            })
        );
      }

      if (Object.keys(mouthpiece).length) {
        requests.push(
          axios
            .put("mouthpieces", {
              order_number: row.order_number,
              serial_number: row.mouthpiece_serial_number,
              ...mouthpiece,
            })
            .then((results) => {
              // console.log("mouthpieces results", results);
              mutate(`mouthpieces/player/${id}`);
            })
        );
      }

      axios
        .all(requests)
        .then(() => {
          // trigger revalidation with remote
          mutate(`mouthpieces/player/${id}`);
          mutate(`players/${currentOrg}`);

          setRow(null);
          setDisabled(false);
          setLoading(false);
          setErrored(false);
        })
        .catch((error) => {
          console.log(error.response);
          setErrored(true);
          setLoading(false);
          setDisabled(false);
        });
    },
    validate: function (values) {
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      // serial_number: yup.number().integer().min( 7 ).required(),
      ddrm_functional: yup
        .string()
        .matches(/^(False|True)$/)
        .required(),
      mouthpiece_functional: yup
        .string()
        .matches(/^(False|True)$/)
        .required(),
      location: yup.string().matches(re).required(),
      comments: yup
        .string()
        .max(500, "Issue comments must be at most 500 characters."),
      mouthpiece_comments: yup
        .string()
        .max(500, "Issue comments must be at most 500 characters."),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit Mouthpiece Serial Number ${
            row.mouthpiece_serial_number ? row.mouthpiece_serial_number : "N/A"
          }`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  className="form-select"
                >
                  {row.ddrm_serial_number ? (
                    [organization, "LOST"].sort().map((location) => {
                      return (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      );
                    })
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Mouthpiece Functional</Form.Label>
                <Form.Control
                  as="select"
                  name="mouthpiece_functional"
                  onChange={formik.handleChange}
                  value={formik.values.mouthpiece_functional}
                  className="form-select"
                >
                  <option value="False">False</option>
                  <option value="True">True</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>Mouthpiece Issue Comments:</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.mouthpiece_comments}
                name="mouthpiece_comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.mouthpiece_comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.mouthpiece_comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Electronics Functional</Form.Label>
                <Form.Control
                  as="select"
                  name="ddrm_functional"
                  onChange={formik.handleChange}
                  value={formik.values.ddrm_functional}
                  className="form-select"
                >
                  <option value="False">False</option>
                  <option value="True">True</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>Electronics Issue Comments:</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.comments}
                name="comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
