import React, { Suspense } from "react";
import PlayerCard from "./PlayerCard";
import CardSearch from "./CardSearch";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import PlayerDetails from "./PlayerDetails";
import PlayerDetailsEmpty from "./PlayerDetailsEmpty";
import {
  searchFilteredPlayersListState,
  playerDetailsIdState,
  playersFilterState,
  showPlayerDetailsState,
  textState,
} from "components/shared/States.js";
import LoadingSpinner from "components/shared/Spinner";
import ErrorBoundary from "components/shared/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function PlayerCards() {
  const searchFilteredPlayersList = useRecoilValue(
    searchFilteredPlayersListState
  );
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const setText = useSetRecoilState(textState);
  const setPlayersFilter = useSetRecoilState(playersFilterState);
  const [playerDetailsId, setPlayerDetailsId] = useRecoilState(
    playerDetailsIdState
  );

  /**
   * Resets the playerDetails module into the correct location
   * when the user switches between different states
   * @param e
   */
  const managePlayerDetailsLocations = (e) => {
    const parentDiv = e.target.closest("div.player-cards");
    const newNode = document.getElementById("player-details");
    parentDiv.appendChild(newNode);
    setShowPlayerDetails(false);
    setPlayerDetailsId(0);
  };

  /**
   * Clears the search input from any text that has been typed in
   */
  const clearPlayerSearchField = () => {
    setText("");
    const playerSearchField = document.getElementById("player-search");
    playerSearchField.value = "";
  };

  /**
   * Filters list of players down to those that have low inventory
   * @param e
   */
  const filterLowInventory = (e) => {
    managePlayerDetailsLocations(e);
    clearPlayerSearchField();

    setPlayersFilter("lowInventory");
  };

  /**
   * Filters list of players down to only those that have orders
   * @param e
   */
  const filterOrders = (e) => {
    managePlayerDetailsLocations(e);
    clearPlayerSearchField();

    setPlayersFilter("orders");
  };

  /**
   * Filters list of players down to only those that have Faults
   * @param e
   */
  const filterFaults = (e) => {
    managePlayerDetailsLocations(e);
    clearPlayerSearchField();

    setPlayersFilter("faults");
  };

  /**
   * Resets any assigned fault, low inventory, or order player filter
   * Clears the player text search field
   * @param e
   */
  const resetPlayerFilters = (e) => {
    e.preventDefault();
    managePlayerDetailsLocations(e);
    clearPlayerSearchField();
    // reset to original playersList
    setPlayersFilter("none");
    setPlayerDetailsId(0);
  };

  return (
    <>
      <div id="player-cards-details-wrapper">
        <div className="row">
          <div className="col col-xl-8 mt-4">
            <ErrorBoundary className="half-screen">
              <Suspense fallback={<LoadingSpinner className="half-screen" />}>
                <CardSearch />
              </Suspense>
            </ErrorBoundary>
          </div>
          <div className="col mt-4">
            <button
              className="btn btn-danger me-2"
              onClick={(e) => {
                e.preventDefault();
                filterFaults(e);
              }}
            >
              Faults
            </button>
            <button
              className="btn btn-info text-white me-2"
              onClick={(e) => {
                e.preventDefault();
                filterLowInventory(e);
              }}
            >
              Low Inventory
            </button>
            <button
              className="btn btn-primary text-white me-2"
              onClick={(e) => {
                e.preventDefault();
                filterOrders(e);
              }}
            >
              Orders
            </button>
            <button
              className="btn btn-secondary text-white me-2"
              onClick={(e) => resetPlayerFilters(e)}
            >
              Clear Filters
            </button>
          </div>
        </div>
        <div className="row">
          {searchFilteredPlayersList?.length > 0 ? (
            <>
              {searchFilteredPlayersList.map((player, index) => (
                <ErrorBoundary className="col" key={`error-boundary-${index}`}>
                  <Suspense fallback={<LoadingSpinner className="col" />}>
                    <PlayerCard
                      mouthpiece_counts={player.mouthpiece_counts}
                      playerId={player.mgs_player_id}
                      player={player}
                      index={index}
                      key={index}
                    />
                  </Suspense>
                </ErrorBoundary>
              ))}
            </>
          ) : (
            <div className="align-items-center d-flex justify-content-center half-screen">
              <h4>
                <FontAwesomeIcon color="dodgerblue" icon={faInfoCircle} />
                <span className="ms-2">No data to display.</span>
              </h4>
            </div>
          )}
        </div>
        <div id="player-details" className="w-full">
          <ErrorBoundary className="">
            <Suspense fallback={<LoadingSpinner className="mt-5 mb-5" />}>
              {playerDetailsId !== 0 ? (
                <PlayerDetails />
              ) : (
                <PlayerDetailsEmpty />
              )}
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}
