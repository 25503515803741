import Alert from "react-bootstrap/Alert";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError error", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("error", error);
    console.log("errorInfo", errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <div
        className={`align-items-center d-flex justify-content-center ${this.props.className}`}
      >
        <Alert variant="danger">The requested resources failed to load.</Alert>
      </div>
    ) : (
      this.props.children
    );
  }
}
