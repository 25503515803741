import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { mutate } from "swr";
import * as yup from "yup";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentOrgState,
  filteredPlayersListState,
  playerDetailsIdState,
  playersListState,
  showDeactivatePlayerModalState,
  showPlayerDetailsState,
} from "components/shared/States";

export default function DeactivatePlayerModal({ playerDetails }) {
  const currentOrg = useRecoilValue(currentOrgState);
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const setPlayersList = useSetRecoilState(playersListState);
  const [playerDetailsId, setPlayerDetailsId] = useRecoilState(
    playerDetailsIdState
  );
  const setFilteredPlayersList = useSetRecoilState(filteredPlayersListState);
  const [
    showDeactivatePlayerModal,
    setShowDeactivatePlayerModal,
  ] = useRecoilState(showDeactivatePlayerModalState);

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const formInitialValues = {
    mgs_player_id: playerDetailsId,
    study_participation: false,
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        mgs_player_id: formInitialValues.mgs_player_id,
        study_participation: formInitialValues.study_participation,
      };

      axios
        .put("study_status", body)
        .then(() => {
          // reset the players list so it will redraw
          setPlayersList([]);
          setFilteredPlayersList([]);

          mutate("players/" + currentOrg).then((result) => {
            setShowPlayerDetails(false);
            let parentDiv = document.getElementById(
              "player-cards-details-wrapper"
            );
            let newNode = document.getElementById("player-details");
            parentDiv.appendChild(newNode);
            setPlayerDetailsId(0);
            setDisabled(false);
            setLoading(false);
            setErrored(false);
            // hide the modal
            setShowDeactivatePlayerModal(false);
          });
        })
        .catch((error) => {
          console.log("study_status error", error.response);
          setLoading(false);
          setErrored(true);
          setDisabled(false);
        });
    },
    validationSchema: yup.object().shape({
      mgs_player_id: yup.string().matches(/\d+/).required(),
      study_participation: yup
        .string()
        .matches(/^(false|true)$/)
        .required(),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setShowDeactivatePlayerModal(false)}
      show={showDeactivatePlayerModal}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {`Deactivate ${playerDetails.first_name} ${playerDetails.last_name}, #${playerDetails.roster_number}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-0">
            <Col>
              <p className="mb-0">
                Are you sure you want to deactivate this player?
              </p>
            </Col>
          </Row>
          <Row className="d-none">
            <Col>
              <Form.Group>
                <Form.Label className="d-none">Player Id</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.mgs_player_id &&
                    formik.touched.mgs_player_id
                  }
                  name="mgs_player_id"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="hidden"
                  value={playerDetailsId}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.mgs_player_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="d-none">Study Participation</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.study_participation &&
                    formik.touched.study_participation
                  }
                  name="study_participation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="hidden"
                  value={formik.values.study_participation}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.study_participation}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowDeactivatePlayerModal(false);
              setDisabled(false);
              setErrored(false);
            }}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            type="submit"
            variant="danger"
            className="d-table"
          >
            <span className="table-row">
              <span className="table-cell">Deactivate</span>
              {loading === true ? (
                <>
                  <div className="ps-2 d-inline">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="light"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </>
              ) : (
                ""
              )}
              {errored === true ? (
                <span className="errored btn-label table-cell">
                  <img
                    src="/img/icn-error-white.svg"
                    alt="errored..."
                    style={{ width: "20px", marginLeft: "2%" }}
                  />
                </span>
              ) : (
                ""
              )}
            </span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
