import React, { Suspense } from "react";

import ErrorBoundary from "components/shared/ErrorBoundary";
import LoadingSpinner from "components/shared/Spinner";
import MouthpiecesPlayerIdTable from "./components/MouthpiecesPlayerIdTable";

export default function MouthpiecesPlayerId() {
  return (
    <>
      <h3 className="mb-4">Mouthpieces by Player</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <MouthpiecesPlayerIdTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
