import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import MouthpiecesPlayerIdModal from "./MouthpiecesPlayerIdModal";
import { SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function MouthpiecesPlayerIdTable() {
  const { id } = useParams();
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: "mouthpiece_serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) => (d.location ? d.location : " "),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Location",
            id: "location",
          },
          {
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Status",
          },
          {
            accessor: "mouthpiece_type",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Type",
          },
        ],
        Header: "Mouthpiece",
      },
      {
        columns: [
          {
            accessor: "ddrm_serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) =>
              d.ddrm_serial_number ? (d.functional ? "True" : "False") : " ",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Functional",
          },
          {
            accessor: "mac_address",
            Header: "MAC",
          },
        ],
        Header: "DDRM",
      },
    ],
    []
  );

  const { data: player } = useSWR(`mouthpieces/player/${id}`);

  return (
    <>
      <div>{`Name: ${player.last_name}, ${player.first_name}`}</div>
      <div>{`Organization: ${player.organization}`}</div>
      <div>{`Position: ${player.ngs_position}`}</div>
      <div>{`Roster Number: ${player.roster_number}`}</div>
      <div>{`Control Case: ${player.case_serial_number ?? "Unassigned"}`}</div>
      <div>{`Control Case DDRC: ${
        player.ddrc_serial_number ?? "Unassigned"
      }`}</div>
      <div className="mb-4">{`Control Case Position: ${
        player.case_position ?? "Unassigned"
      }`}</div>
      <Table
        className="partial-screen"
        columns={columns}
        data={player.mouthpieces}
        onClick={setRow}
      />
      {row ? (
        <MouthpiecesPlayerIdModal
          id={id}
          organization={player.organization}
          row={row}
          setRow={setRow}
        />
      ) : null}
    </>
  );
}
