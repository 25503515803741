import { useRecoilState, useSetRecoilState } from "recoil";
import {
  playersFilterState,
  showPlayerDetailsState,
  textState,
} from "components/shared/States";
import React from "react";

export default function CardSearch() {
  const [text, setText] = useRecoilState(textState);
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const setPlayersFilter = useSetRecoilState(playersFilterState);

  /**
   * This onChange event triggers the Recoil
   * setFilteredPlayersList selector/function in States.js
   * to fuzzy search through active players
   * @param event
   */
  const onChange = (event) => {
    setText(event.target.value);
    if (event.target.value.length > 0) {
      setPlayersFilter("textSearch");
    } else {
      setPlayersFilter("none");
    }
  };

  /**
   * This onFocus event will move the #player-details div to the parent
   * .player-cards div when a user clicks in the search so we don't
   * run into issues with the #player-details div getting removed from the DOM
   * due to the filtering excluding the DOM element it is attached to
   * @param event
   */
  const onFocus = (event) => {
    const parentDiv = event.target.closest("div.player-cards");
    const newNode = document.getElementById("player-details");
    parentDiv.appendChild(newNode);
    setShowPlayerDetails(false);
  };

  return (
    <div className="mb-4">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          aria-label="Search"
          placeholder="Search Players..."
          value={text}
          onChange={onChange}
          onFocus={onFocus}
          id="player-search"
        />
        <span className="input-group-text">
          <img
            src="img/icn-search.svg"
            className=""
            style={{
              width: "15px",
            }}
            alt={`Search`}
          />
        </span>
      </div>
    </div>
  );
}
