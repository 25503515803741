import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";

export default function LIOForm({ clickHandler, mouthpieces, organization }) {
  const [value, setValue] = useState(
    JSON.stringify({
      ddrm: mouthpieces[0].ddrm_serial_number,
      mouthpiece: mouthpieces[0].mouthpiece_serial_number,
    })
  );

  return (
    <div className="d-flex justify-content-center ">
      <Form>
        <Form.Control
          as="select"
          onChange={(e) => setValue(e.target.value)}
          size="sm"
        >
          {mouthpieces.map((mouthpiece) => (
            <option
              key={mouthpiece.ddrm_serial_number}
              value={JSON.stringify({
                ddrm: mouthpiece.ddrm_serial_number,
                mouthpiece: mouthpiece.mouthpiece_serial_number,
              })}
            >
              {mouthpiece.mouthpiece_serial_number}
            </option>
          ))}
        </Form.Control>
        <Button
          className="ml-2"
          onClick={() => clickHandler(organization, value)}
          size="sm"
          variant="danger"
        >
          Ship
        </Button>
      </Form>
    </div>
  );
}
