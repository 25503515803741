import React, { useState } from "react";

import ConfirmSignInForm from "./components/ConfirmSignInForm";
import NewPasswordForm from "./components/NewPasswordForm";
import SignInForm from "./components/SignInForm";

import "./SignIn.scss";

export default function SignIn({ setGroup }) {
  const [challenge, setChallenge] = useState(null);
  const [unconfirmedUser, setUnconfirmedUser] = useState(null);

  switch (challenge) {
    case "NEW_PASSWORD_REQUIRED":
      return (
        <NewPasswordForm
          setChallenge={setChallenge}
          setUnconfirmedUser={setUnconfirmedUser}
          unconfirmedUser={unconfirmedUser}
        />
      );
    case "SMS_MFA":
      return (
        <ConfirmSignInForm
          setChallenge={setChallenge}
          setUnconfirmedUser={setUnconfirmedUser}
          setGroup={setGroup}
          unconfirmedUser={unconfirmedUser}
        />
      );
    default:
      return (
        <SignInForm
          setChallenge={setChallenge}
          setUnconfirmedUser={setUnconfirmedUser}
        />
      );
  }
}
