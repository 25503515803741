import React, { Suspense } from "react";
import ErrorBoundary from "components/shared/ErrorBoundary";
import LoadingSpinner from "components/shared/Spinner";
import OrganizationsTable from "./components/OrganizationsTable";

export default function organizations() {
  return (
    <>
      <h3 className="mb-4">Organizations</h3>
      <ErrorBoundary className="full-screen">
        <Suspense fallback={<LoadingSpinner className="full-screen" />}>
          <OrganizationsTable />
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
