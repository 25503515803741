import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import config from "config.json";

export default function DDRMsModal({ organizations, row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const initialValues = {
    allocation_type: row.allocation_type,
    calibration_type: row.calibration_type,
    firmware_version: row.firmware_version ? row.firmware_version : "",
    functional: row.functional ? "TRUE" : "FALSE",
    location: row.location,
    comments: row.comments ? row.comments : "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        serial_number: row.ddrm_serial_number,
      };
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "comments":
              body[key] = values[key].trim() ? values[key].trim() : null;
              break;
            case "firmware_version":
              body[key] = values[key] ? values[key] : null;
              break;
            case "functional":
              body[key] = values[key] === "TRUE";
              break;
            default:
              body[key] = values[key];
          }
        }
      }
      axios
        .put("ddrms", body)
        .then(() => {
          mutate("ddrms");
          setRow(null);
          setDisabled(false);
          setLoading(false);
          setErrored(false);
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMsg(error.response);
          setErrored(true);
        });
    },
    validate: function (values) {
      values.comments = values.comments.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${row.ddrm_serial_number}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Firmware</Form.Label>
                <Form.Control
                  as="select"
                  name="firmware_version"
                  onChange={formik.handleChange}
                  value={formik.values.firmware_version}
                  className="form-select"
                >
                  <option value=""></option>
                  {config.ddrmFirmwareVersions.map((version) => (
                    <option key={version}>{version}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>DDRM Functional</Form.Label>
                <Form.Control
                  as="select"
                  name="functional"
                  onChange={formik.handleChange}
                  value={formik.values.functional}
                  className="form-select"
                >
                  <option value="FALSE">FALSE</option>
                  <option value="TRUE">TRUE</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  className="form-select"
                >
                  {organizations.map((organization) => {
                    const abbreviation = organization.abbreviation;
                    return (
                      <option key={abbreviation} value={abbreviation}>
                        {abbreviation}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Allocation</Form.Label>
                <Form.Control
                  as="select"
                  name="allocation_type"
                  onChange={formik.handleChange}
                  value={formik.values.allocation_type}
                  className="form-select"
                >
                  <option value="NFL_MANUFACTURING">NFL MANUFACTURING</option>
                  <option value="NFL_TESTING">NFL TESTING</option>
                  <option value="NSWG_MANUFACTURING">NSWG MANUFACTURING</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Calibration</Form.Label>
                <Form.Control
                  as="select"
                  name="calibration_type"
                  onChange={formik.handleChange}
                  value={formik.values.calibration_type}
                  className="form-select"
                >
                  <option value="EXTENDED">EXTENDED</option>
                  <option value="FULL">FULL</option>
                  <option value="INITIAL">INITIAL</option>
                  <option value="NONE">NONE</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  isInvalid={
                    !!formik.errors.comments && formik.touched.comments
                  }
                  name="comments"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  rows={3}
                  value={formik.values.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.comments}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {errorMsg ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <Alert variant="danger">{errorMsg}</Alert>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update{" "}
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
