import Table from "components/shared/Table";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import React, { useMemo, useState } from "react";
import { NoColumnFilter } from "utils/filters";
import axios from "axios";
import { mutate } from "swr";
import { useRecoilValue } from "recoil";
import { currentOrgState } from "components/shared/States";
import {
  getPrettyMouthpieceColor,
  slugify,
  addOneDayOrPushToMonday,
} from "utils/helpers";

export default function PlayerOrdersTable({ orders, playerDetailsId }) {
  const currentOrg = useRecoilValue(currentOrgState);
  const [orderRow, setOrderRow] = useState(null);
  const [editOrder, setEditOrder] = useState(false);

  const receiveMouthpiece = () => {
    const today = new Date();

    axios
      .all([
        axios.put("ddrms", {
          serial_number: orderRow.ddrm_serial_number,
          location: currentOrg,
        }),
        axios.put("mouthpieces", {
          order_number: orderRow.order_number,
          delivery_date: `${today.getFullYear()}-${String(
            today.getMonth() + 1
          ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`,
          status: "DELIVERED",
        }),
      ])
      .then(() => {
        mutate(`mouthpieces/ordered/player/${playerDetailsId}`);
        mutate(`mouthpieces/player/${playerDetailsId}`);
        handleModalClose();
      })
      .catch((err) => console.log(err));
  };

  const handleModalClose = () => {
    setOrderRow(null);
    setEditOrder(false);
  };

  const handleReceiveClick = (rowOrder) => {
    setOrderRow(rowOrder);
    setEditOrder(true);
  };

  const orders_columns = useMemo(
    () => [
      {
        accessor: "mouthpiece_serial_number",
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Serial",
      },
      {
        Cell: ({ cell }) =>
          cell.row.original.status === "SHIPPED" ? (
            <div className="negative-margin">
              <Button
                onClick={() => handleReceiveClick(cell.row.original)}
                size="sm"
                type="button"
                variant="primary"
              >
                Receive
              </Button>
            </div>
          ) : null,
        Header: "Receive",
      },
      {
        accessor: "type",
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Type",
      },
      {
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Colors",
        Cell: ({ cell }) =>
          cell.row.original.colors ? (
            <div>
              <span
                className={`color-block ` + slugify(cell.row.original.colors)}
              >
                &nbsp;
              </span>
              {getPrettyMouthpieceColor(cell.row.original.colors)}
            </div>
          ) : null,
      },
      {
        accessor: "status",
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Status",
      },
      // {
      //   accessor: "requested_delivery_date",
      //   Filter: NoColumnFilter,
      //   filter: "exactText",
      //   Header: "Requested Delivery",
      // },
      {
        accessor: "requested_delivery_date_pushed",
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Requested Delivery",
        Cell: ({ cell }) =>
          cell.row.original.requested_delivery_date ? (
            <div>
              {addOneDayOrPushToMonday(
                cell.row.original.requested_delivery_date
              )}
            </div>
          ) : null,
      },
      // {
      //   accessor: "tracking_number",
      //   Filter: NoColumnFilter,
      //   filter: "exactText",
      //   Header: "Tracking",
      // },
      {
        accessor: "order_number",
        Filter: NoColumnFilter,
        filter: "exactText",
        Header: "Order Number",
        show: false,
      },
    ],
    []
  );

  return (
    <>
      <Table
        className="mt-5"
        columns={orders_columns}
        data={orders}
        onClick={setOrderRow}
      />
      <Modal
        animation={false}
        background="static"
        centered
        onHide={() => {
          handleModalClose();
        }}
        show={editOrder}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Confirm Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderRow
            ? `Receive mouthpiece ${orderRow.mouthpiece_serial_number} for ${orderRow.first_name} ${orderRow.last_name}, #${orderRow.roster_number}?`
            : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleModalClose()}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => receiveMouthpiece()}
            type="button"
            variant="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
