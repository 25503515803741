import React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import {
  playerDetailsState,
  playerDetailsIdState,
  playerDetailsIndicatorPositionState,
  showPlayerDetailsState,
} from "components/shared/States";
import Ratio from "react-bootstrap/Ratio";
import Image from "react-bootstrap/Image";
import { getRandomImageByPosition } from "utils/helpers";

export default function PlayerCard(props) {
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const setPlayerDetails = useSetRecoilState(playerDetailsState);
  const [playerDetailsId, setPlayerDetailsId] = useRecoilState(
    playerDetailsIdState
  );
  const setPlayerDetailsIndicatorPosition = useSetRecoilState(
    playerDetailsIndicatorPositionState
  );

  const thisPlayer = props.player;

  const mouthPieceCounts = props.mouthpiece_counts;
  const randomImage = getRandomImageByPosition(thisPlayer.ngs_position);

  // Set up some basic variables to show inventory, orders, and fault icons
  let mc_faults = false;
  let mc_ordered = false;
  let mc_organization = false;

  if (mouthPieceCounts.critical_faults > 0) {
    mc_faults = true;
  }

  if (mouthPieceCounts.ordered > 0) {
    mc_ordered = true;
  }

  if (mouthPieceCounts.functional + mouthPieceCounts.ordered < 3) {
    mc_organization = true;
  }

  /**
   * Determines where to inject the player detail information and displays it to the user
   * @param event
   * @param playerId
   */
  function showPlayerInfo(event, playerId, player) {
    event.preventDefault();

    let playerBlock = event.target.closest("article");
    let parentDiv = playerBlock.parentNode;
    let newNode = document.getElementById("player-details");

    // if this player's card is open, close it and reset the player id
    if (playerId === playerDetailsId) {
      setShowPlayerDetails(false);
      setPlayerDetailsId(0);
      parentDiv.appendChild(newNode);
      setPlayerDetails([]);
    } else {
      setPlayerDetailsId(playerId);
      setPlayerDetails(player);

      let playerBlockIndex =
        parseInt(playerBlock.getAttribute("data-index")) + 1;
      let bounds = playerBlock.getBoundingClientRect();
      let playerBlockMiddle =
        (bounds.right - bounds.left) / 2 + (bounds.left - 80);
      setPlayerDetailsIndicatorPosition(playerBlockMiddle);

      let totalCards = getNumberOfCards();
      let cardsInRows = getNumberOfCardsInRows();
      let placementRow = Math.ceil(playerBlockIndex / cardsInRows);
      let placementAfterIndex = placementRow * cardsInRows;

      let targetDiv = document.querySelector(
        "[data-index='" + placementAfterIndex + "']"
      );

      if (totalCards <= cardsInRows) {
        parentDiv.insertBefore(newNode, null);
      } else {
        parentDiv.insertBefore(newNode, targetDiv);
      }
      setShowPlayerDetails(true);
    }
  }

  /**
   * Returns the total number of cards in the active tab pane
   * @returns {number}
   */
  function getNumberOfCards() {
    return document.querySelectorAll(".tab-pane.active article").length;
  }

  /**
   * Returns the number of cards in the row of cards
   * see https://codesandbox.io/s/grid-layout-fill-last-row-getboundingclientrect-left-forked-fo9b4?file=/src/script.js
   * @returns {*}
   */
  function getNumberOfCardsInRows() {
    return Array.prototype.reduce.call(
      document.querySelectorAll("article"),
      function (prev, next) {
        let ret;
        if (!prev[2]) {
          ret = next.getBoundingClientRect().left;
          // if increasing, increment counter
          if (!(prev[0] > -1 && ret < prev[1])) {
            prev[0]++;
          } else {
            prev[2] = 1;
          } // else stop counting
        }
        return [prev[0], ret, prev[2]]; // [counter, elem, stop-counting]
      },
      [0, null, 0]
    )[0];
  }

  return (
    <>
      <article
        className={`col-xs-24 col-sm-12 col-md-8 col-xl-8 col-2xl-6 col-3xl-4 col-4xl-4 col-5xl-3 mb-4`}
        key={props.index.toString()}
        onClick={(event) => {
          showPlayerInfo(event, props.playerId, thisPlayer);
        }}
        id={"player-" + props.playerId}
        data-index={props.index}
      >
        <div
          className={`card player-card ${
            props.playerId === playerDetailsId
              ? "bg-primary bg-gradient bg-opacity-10"
              : "bg-white"
          }`}
        >
          <div className="card-body">
            <div className="player-image position-relative">
              <Ratio aspectRatio={"1x1"}>
                <picture>
                  <source
                    srcSet={thisPlayer.image}
                    media="(min-width: 800px)"
                  />
                  <Image
                    src={randomImage}
                    alt={`${props.player.first_name}`}
                    fluid="true"
                  />
                </picture>
              </Ratio>

              {mc_faults === true ||
              mc_ordered === true ||
              mc_organization === true ? (
                <div
                  className="vstack gap-2 position-absolute top-5 end-2 h-50"
                  style={{ width: "10%" }}
                >
                  {mc_faults === true && (
                    <img
                      src="img/icn-fault.svg"
                      className="mb-1"
                      alt={`Faults ${mouthPieceCounts.critical_faults}`}
                      title={`Faults ${mouthPieceCounts.critical_faults}`}
                    />
                  )}

                  {mc_ordered === true && (
                    <img
                      src="img/icn-order.svg"
                      className="mb-1"
                      alt={`Orders ${mouthPieceCounts.orders}`}
                      title={`Orders ${mouthPieceCounts.orders}`}
                    />
                  )}

                  {mc_organization === true && (
                    <img
                      src="img/icn-inventory.svg"
                      className="mb-1"
                      alt={`Inventory ${mouthPieceCounts.organization}`}
                      title={`Inventory ${mouthPieceCounts.organization}`}
                    />
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="player-roster-number bg-white position-absolute top-85 px-2">
                {thisPlayer.roster_number} / {thisPlayer.ngs_position}
              </div>
            </div>
            <div className="player-info">
              <div className="player-first-name">
                {thisPlayer.first_name}
                {thisPlayer.nickname ? (
                  <span className="ms-2">"{thisPlayer.nickname}"</span>
                ) : (
                  ""
                )}
              </div>
              <div className="player-last-name">{thisPlayer.last_name}</div>
              <div className="liner">&nbsp;</div>
              {thisPlayer ? (
                <div className="player-deets">
                  <span>ID: {props.playerId}</span>
                  <br />
                  <span>
                    Functional: {thisPlayer.mouthpiece_counts.functional}{" "}
                  </span>
                  <br />
                  <span>Orders: {thisPlayer.mouthpiece_counts.ordered}</span>
                  <br />
                  <span>
                    Faults: {thisPlayer.mouthpiece_counts.critical_faults}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
