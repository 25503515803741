import React from "react";

export default function CasePosition(props) {
  const n = 20;
  let position = 0;
  let layoutPosition = 0;
  let caseNumber = 0;

  // check to make sure we have some values otherwise assign NA
  if (props.position !== 0) {
    position = props.position;
    layoutPosition = position - 1; // subtract 1 since array is zero based
    caseNumber = props.case;
  } else {
    position = "NA";
    caseNumber = "NA";
    layoutPosition = -1;
  }

  return (
    <div className="d-flex flex-column">
      <strong className="text-center">
        Case {caseNumber} | Pos. {position}
      </strong>
      <div className="case-background d-flex flex-row flex-wrap">
        {[...Array(n)].map((e, i) => (
          <div
            className={`case-mp-holder ${
              i === layoutPosition ? "selected" : "unselected"
            }`}
            key={i}
            data-position={i}
          >
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  );
}
