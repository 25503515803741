import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

/**
 * Default input search filter
 * @param filterValue
 * @param preFilteredRows
 * @param setFilter
 * @returns {JSX.Element}
 * @constructor
 */
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div className="input-group mx-auto w-100 d-flex flex-nowrap justify-content-center">
      <input
        className="filter-field flex"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`${count} record(s)`}
        value={filterValue || ""}
      />
      <div className="input-group-append d-none d-xl-block">
        <span className="input-group-text" id="basic-addon2">
          <img src="/img/icn-search.svg" alt="Search" width="10" />
        </span>
      </div>
    </div>
  );
}

/**
 * Creates a basic dropdown/select filter
 * @param filterValue
 * @param setFilter
 * @param preFilteredRows
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div className="input-group mx-auto w-100 d-flex justify-content-center">
      <select
        className="filter-field"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        value={filterValue}
      >
        <option value="">All</option>
        {options.includes(" ") ? <option value=" ">Blank</option> : null}
        {options.sort().map((option, i) =>
          option && option !== " " ? (
            <option key={i} value={option}>
              {option}
            </option>
          ) : null
        )}
      </select>
    </div>
  );
}

/**
 * Creates a basic dropdown/select filter
 * @param filterValue
 * @param setFilter
 * @param preFilteredRows
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export function SelectBooleanColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <div className="input-group mx-auto w-100 d-flex justify-content-center">
      <select
        className="filter-field"
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        value={filterValue}
      >
        <option value="">All</option>
        {options.includes(" ") ? <option value=" ">Blank</option> : null}
        {options.sort().map((option, i) =>
          option && option !== " " ? (
            <option key={i} value={option}>
              {option.toString().toUpperCase()}
            </option>
          ) : null
        )}
      </select>
    </div>
  );
}

/**
 * Sort text options alphabetically and numbers consecutively
 * @param options
 * @returns {*[]}
 */
const alphabeticallySortOptions = (options) => {
  let sortedOptions = [];
  // sort strings
  if (isNaN(options[0])) {
    sortedOptions = [...options].sort((a, b) =>
      `${a}` > `${b}` ? 1 : `${a}` < `${b}` ? -1 : 0
    );
  } else {
    // sort numbers
    sortedOptions = [...options].sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  }

  return sortedOptions;
};

/**
 * Build options for the multi-select filter
 * @param options
 * @returns {*[]}
 */
function prepareForOptions(options) {
  const newSelectOptions = [];
  const alphabetizedOptions = alphabeticallySortOptions(options);

  alphabetizedOptions.forEach(function (item, index) {
    newSelectOptions.push({ value: item, label: item });
  });

  return newSelectOptions;
}

/**
 * Multi-select filter
 * @param filterValue
 * @param setFilter
 * @param preFilteredRows
 * @param id
 * @param defaultValue
 * @returns {JSX.Element}
 * @constructor
 */
export function MultiSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, defaultValue },
}) {
  const animatedComponents = makeAnimated();

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const newOptions = prepareForOptions(options);

  // Render a multi-select box
  return (
    <div style={{ maxWidth: "300px" }} className="mx-auto">
      <Select
        isMulti
        components={animatedComponents}
        options={newOptions}
        className="basic-multi-select mx-auto"
        classNamePrefix="select"
        defaultValue={defaultValue}
        onChange={(e) => {
          const allValues = [];
          e.forEach(function (item, index) {
            allValues.push(item.value);
          });

          allValues.filter(Boolean);
          setFilter(allValues && allValues.length ? allValues : undefined);
        }}
      />
    </div>
  );
}

/**
 * Returns a "filter" that is simply an empty span
 * @param filterValue
 * @param setFilter
 * @param preFilteredRows
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
export function NoColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return <span> </span>;
}
