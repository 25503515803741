import React from "react";
import { useRecoilValue } from "recoil";
import { playerDetailsIndicatorPositionState } from "components/shared/States";

/** JUST AN EMPTY DIV CONTAINER **/

export default function PlayerDetailsEmpty() {
  const playerDetailsIndicatorPosition = useRecoilValue(
    playerDetailsIndicatorPositionState
  );
  const style = {
    left: playerDetailsIndicatorPosition + "px",
  };

  return (
    <div
      className={`player-details w-full mb-4 p-4 bg-light shadow-inset position-relative d-none`}
    >
      <div className="triangle-indicator-wrapper" style={style}>
        <div className="triangle-indicator">&nbsp;</div>
      </div>
      <div className="d-flex flex-row mb-4">&nbsp;</div>
    </div>
  );
}
