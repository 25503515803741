const { atom, selector } = require("recoil");
const fuzzysort = require("fuzzysort");

/************** FUNCTIONS USED BY SELECTORS **************/

/**
 * Sorts a list of players by last name
 * @param playersList
 * @returns {*[]}
 */
const sortPlayers = (playersList) => {
  return [...playersList].sort((a, b) =>
    `${a.last_name}` > `${b.last_name}`
      ? 1
      : `${a.last_name}` < `${b.last_name}`
      ? -1
      : 0
  );
};

/**
 * Maps the part number to the appropriate revision
 * @param ddrm
 * @returns {*}
 */
function changePartNumberToRevision(ddrm) {
  let newDdrm = { ...ddrm };

  if (newDdrm.part_number === "16030-00020") {
    newDdrm.revision = "B";
  } else if (newDdrm.part_number === "0428R003") {
    newDdrm.revision = "C";
  } else if (newDdrm.part_number === "C2001R001") {
    newDdrm.revision = "C";
  } else if (newDdrm.part_number === "C2001R002") {
    newDdrm.revision = "C";
  } else if (newDdrm.part_number === "C2001R005") {
    newDdrm.revision = "C";
  } else if (newDdrm.part_number === "2001R002") {
    newDdrm.revision = "D";
  } else if (newDdrm.part_number === "D2001R002") {
    newDdrm.revision = "D";
  } else if (newDdrm.part_number === "E2001R002") {
    newDdrm.revision = "E";
  }

  return newDdrm;
}

/************** SELECTORS **************/

/**
 * Returns a list of active players from active player search
 * @type {RecoilState<unknown>}
 */
const filteredCardsState = selector({
  key: "filteredCards",
  get: ({ get }) => {
    const text = get(textState);
    const allPlayers = get(allPlayersListState);

    // filter the players to only include active players
    const activePlayers = allPlayers.filter(
      (player) => player.study_participation === true
    );

    // run through the filtered players with the fuzzy search
    const results = fuzzysort.go(text, activePlayers, {
      allowTypo: false,
      keys: [
        "first_name",
        "last_name",
        "nickname",
        "ngs_position",
        "roster_number",
      ],
    });
    const filteredPlayers = [];

    // build the new updated list of filtered/searched players
    function buildFilteredPlayersList(item, index) {
      filteredPlayers[index] = item.obj;
    }

    results.forEach(buildFilteredPlayersList);

    return filteredPlayers;
  },
});

/**
 * Returns a list of inactive players from inactive player search
 * @type {RecoilState<unknown>}
 */
const filteredInactiveCardsState = selector({
  key: "filteredInactiveCards", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const text = get(textState);
    const filteredPlayersList = get(playersInactiveListState);
    const results = fuzzysort.go(text, filteredPlayersList, {
      allowTypo: false,
      keys: ["first_name", "last_name", "ngs_position", "roster_number"],
    });
    const filteredPlayers = [];

    function buildFilteredPlayersList(item, index) {
      filteredPlayers[index] = item.obj;
    }

    results.forEach(buildFilteredPlayersList);
    // console.log(filteredPlayers);

    return filteredPlayers;
  },
});

/**
 * Returns an object with active and inactive players based on study_participation value
 * @type {RecoilState<unknown>}
 */
const managedTeamState = selector({
  key: "manageTeam",
  get: ({ get }) => {
    const allPlayers = get(allPlayersListState);
    let sortedActivePlayers;
    let sortedInactivePlayers;

    // create active players object
    const activePlayers = allPlayers.filter(
      (player) => player.study_participation === true
    );

    if (activePlayers.length > 0) {
      sortedActivePlayers = sortPlayers(activePlayers);
    } else {
      sortedActivePlayers = activePlayers;
    }

    // create inactive players object
    const inactivePlayers = allPlayers.filter(
      (player) => player.study_participation === false
    );

    if (inactivePlayers.length > 0) {
      sortedInactivePlayers = sortPlayers(inactivePlayers);
    } else {
      sortedInactivePlayers = inactivePlayers;
    }

    return {
      activePlayers: sortedActivePlayers,
      inactivePlayers: sortedInactivePlayers,
    };
  },
});

/**
 * Filters the list of active players by a
 * filter applied by the user clicking on a button in the UI
 * @type {RecoilState<unknown>}
 */
const searchFilteredPlayersListState = selector({
  key: "searchFilteredPlayersList",
  get: ({ get }) => {
    const filter = get(playersFilterState);
    const activePlayersList = get(activePlayersListState);
    const searchCardsList = get(filteredCardsState);

    switch (filter) {
      case "faults":
        return activePlayersList.filter(
          (player) => player.mouthpiece_counts.critical_faults > 0
        );
      case "lowInventory":
        return activePlayersList.filter(
          (player) =>
            player.mouthpiece_counts.functional +
              player.mouthpiece_counts.ordered <
            3
        );
      case "orders":
        return activePlayersList.filter(
          (player) => player.mouthpiece_counts.ordered > 0
        );
      case "textSearch":
        return searchCardsList;
      default:
        return activePlayersList;
    }
  },
});

/**
 * Filters the list of player mouthpieces down to ones
 * that are physically located at the Team location
 * @type {RecoilState<unknown>}
 */
const mouthpiecesPresentTableDataState = selector({
  key: "mouthpiecesPresentTableData",
  get: ({ get }) => {
    const playerMouthpieces = get(playerMouthpiecesState);

    let mouthpieces = {};
    if (playerMouthpieces.length > 0) {
      mouthpieces = playerMouthpieces.filter(
        (mouthpiece) =>
          mouthpiece.status === "DELIVERED" &&
          mouthpiece.location !== "BIO" &&
          mouthpiece.location !== "OPRO" &&
          mouthpiece.location !== "MGC"
      );
    }

    return mouthpieces;
  },
});

/**
 * Loops through DDRM data and adds the appropriate
 * revision information based on the part number
 * @type {RecoilState<unknown>}
 */
const filteredDDRMsDataState = selector({
  key: "filteredDDRMsData",
  get: ({ get }) => {
    const ddrmsData = get(ddrmsDataState);

    let filteredData = [];
    for (let i = 0; i < ddrmsData.length; i++) {
      let updatedData = changePartNumberToRevision(ddrmsData[i]);
      filteredData.push(updatedData);
    }

    return filteredData;
  },
});

/************** ATOMS **************/

const allPlayersListState = atom({
  key: "allPlayersList",
  default: [],
});

const activePlayersListState = atom({
  key: "activePlayersList",
  default: [],
});

const cognitoGroupState = atom({
  key: "cognitoGroup",
  default: "",
});

const currentOrgState = atom({
  key: "currentOrg",
  default: "",
});

const ddrmsDataState = atom({
  key: "ddrmsData",
  default: [],
});

const ddrmSerialNumberState = atom({
  key: "ddrmSerialNumber",
  default: null,
});

const faultReasonState = atom({
  key: "faultReason",
  default: [],
});

const filteredPlayersListState = atom({
  key: "filteredPlayersList",
  default: [],
});

const filteredInactivePlayersListState = atom({
  key: "filteredInactivePlayersList",
  default: [],
});

const inActivePlayersListState = atom({
  key: "inActivePlayersList",
  default: [],
});

const navigationValueState = atom({
  key: "navigation",
  default: "/",
});

const playerDetailsState = atom({
  key: " playerDetails",
  default: 0,
});

const playerDetailsIdState = atom({
  key: " playerDetailsId",
  default: 0,
});

const playerDetailsIndicatorPositionState = atom({
  key: " playerDetailsIndicatorPosition",
  default: 0,
});

const playerMouthpiecesState = atom({
  key: "playerMouthpieces",
  default: [],
});

const playerOrdersCountState = atom({
  key: "playerOrdersCount",
  default: false,
});

const playersFilterState = atom({
  key: "playersFilter",
  default: "none",
});

const playersListState = atom({
  key: "playersList",
  default: [],
});

const playersInactiveListState = atom({
  key: "playersInactiveList",
  default: [],
});

const showDeactivatePlayerModalState = atom({
  key: "showDeactivatePlayerModal",
  default: false,
});

const showResolveFaultsModalState = atom({
  key: "showResolveFaultsModal",
  default: false,
});

const showNicknameModalState = atom({
  key: "showNicknameModal",
  default: false,
});

const showOrderMouthguardModalState = atom({
  key: "showOrderMouthguardModal",
  default: false,
});

const showPlayerDetailsState = atom({
  key: "showPlayerDetails",
  default: false,
});

const tablePageIndexState = atom({
  key: "tablePageIndex",
  default: 0,
});

const teamsActiveTabState = atom({
  key: "teamsActiveTab",
  default: "player-cards",
});

const textState = atom({
  key: "text",
  default: "",
});

export {
  /************** SELECTORS **************/
  managedTeamState,
  searchFilteredPlayersListState,
  filteredCardsState,
  filteredDDRMsDataState,
  filteredInactiveCardsState,
  mouthpiecesPresentTableDataState,
  /************** ATOMS **************/
  allPlayersListState,
  activePlayersListState,
  inActivePlayersListState,
  cognitoGroupState,
  currentOrgState,
  ddrmsDataState,
  ddrmSerialNumberState,
  faultReasonState,
  filteredPlayersListState,
  filteredInactivePlayersListState,
  showDeactivatePlayerModalState,
  showResolveFaultsModalState,
  showNicknameModalState,
  navigationValueState,
  playerDetailsState,
  playerDetailsIdState,
  playerDetailsIndicatorPositionState,
  playerOrdersCountState,
  playersFilterState,
  playersInactiveListState,
  playersListState,
  playerMouthpiecesState,
  showOrderMouthguardModalState,
  showPlayerDetailsState,
  tablePageIndexState,
  teamsActiveTabState,
  textState,
};
