import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

export default function FaultsModal({ row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const initialValues = {
    status: row.status,
    comments: row.comments ? row.comments : "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        id: row.id,
      };
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "comments":
              body[key] = values[key].trim() ? values[key].trim() : null;
              break;
            default:
              body[key] = values[key];
          }
        }
      }
      axios
        .put("faults/id", body)
        .then(() => {
          mutate("faults");
          setRow(null);
          setDisabled(false);
          setLoading(false);
          setErrored(false);
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMsg(error.response);
          setErrored(true);
        });
    },
    validate: function (values) {
      values.comments = values.comments.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${row.id}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="form-select"
                >
                  <option value="ALERT">ALERT</option>
                  <option value="IGNORED">IGNORED</option>
                  <option value="NEW">NEW</option>
                  <option value="RESOLVED">RESOLVED</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.comments}
                name="comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
          {errorMsg ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <Alert variant="danger">{errorMsg}</Alert>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
