import { Link } from "react-router-dom";
import React, { useMemo } from "react";
import useSWR from "swr";

import { SelectColumnFilter } from "../../../../utils/filters";
import Table from "../../../shared/Table";

export default function SummaryTable({ organization }) {
  const columns = useMemo(
    () => [
      {
        columns: [
          {
            Cell: ({ cell }) => (
              <Link
                to={`/mouthpieces/player/id/${cell.row.original.mgs_player_id}`}
              >
                {`${cell.row.original.last_name}, ${cell.row.original.first_name}`}
              </Link>
            ),
            Header: "Name",
          },
          {
            accessor: "ngs_position",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Position",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
        ],
        Header: "Player",
      },
      {
        columns: [
          {
            accessor: (d) => d.mouthpiece_counts.organization,
            Header: "Organization",
            id: "organization_count",
          },
          {
            accessor: (d) => d.mouthpiece_counts.technical_support,
            Header: "Technical Support",
            id: "technical_support_count",
          },
          {
            accessor: (d) => d.mouthpiece_counts.ordered,
            Header: "Ordered",
            id: "ordered_count",
          },
          {
            accessor: (d) => d.mouthpiece_counts.retired,
            Header: "Retired",
            id: "retired_count",
          },
        ],
        Header: "Mouthpiece Counts",
      },
    ],
    []
  );

  const { data: mouthpieces } = useSWR(
    `mouthpieces/organization/${organization}`
  );

  let np = [...mouthpieces].sort((a, b) =>
    `${a.last_name}, ${a.first_name}` > `${b.last_name}, ${b.first_name}`
      ? 1
      : `${a.last_name}, ${a.first_name}` < `${b.last_name}, ${b.first_name}`
      ? -1
      : 0
  );

  return <Table className="half-screen" columns={columns} data={np} />;
}
