import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import { SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function CriticalFaultsTable() {
  const [row, setRow] = useState(null);
  const [show, setShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: "serial_number",
            Header: "Serial",
          },
        ],
        Header: "Mouthpiece",
      },
      {
        columns: [
          {
            accessor: "organization",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Organization",
          },
          {
            accessor: (d) => `${d.last_name}, ${d.first_name}`,
            Header: "Name",
          },
          {
            accessor: "ngs_position",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Position",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
        ],
        Header: "Player",
      },
      {
        columns: [
          {
            Cell: ({ cell }) => (
              <Button
                onClick={() => handleResolveClick(cell.row.original)}
                size="sm"
                type="Button"
                variant="danger"
              >
                Resolve
              </Button>
            ),
            Header: "Resolve Fault",
          },
        ],
        Header: "Action",
      },
    ],
    []
  );

  const { data: faults } = useSWR("faults/critical");

  const handleConfirmClick = () => {
    axios
      .all([
        axios.put("ddrms", {
          serial_number: row.ddrm_serial_number,
          functional: false,
        }),
        axios.put("faults/ddrm", {
          ddrm_serial_number: row.ddrm_serial_number,
          status: "Resolved",
        }),
      ])
      .then(() => {
        mutate("faults/critical");
        mutate("mouthpieces/low-inventory/organizations");
        handleModalClose();
      })
      .catch((error) => console.log(error.response));
  };
  const handleModalClose = () => {
    setRow(null);
    setShow(false);
  };
  const handleResolveClick = (row) => {
    setRow(row);
    setShow(true);
  };

  return (
    <>
      <Table className="half-screen" columns={columns} data={faults} />
      <Modal
        animation={false}
        background="static"
        centered
        onHide={() => handleModalClose()}
        show={show}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Confirm Resolution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {row ? `Resolve fault(s) for mouthpiece ${row.serial_number}?` : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleModalClose()}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmClick()}
            type="button"
            variant="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
