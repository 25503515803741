import axios from "axios";
import React, { useState, useEffect } from "react";

/**
 * This grabs the Redoc documentation from the remote API server and renders it into an iframe
 * @returns {JSX.Element}
 * @constructor
 */
export default function Redoc() {
  const [content, setContent] = useState("");

  useEffect(() => {
    axios({
      method: "get",
      url: "https://app.test.basim.biocorellc.io/openapi.json",
    }).then(function (response) {
      setContent(JSON.stringify(response.data));

      const srcHtml =
        "<!DOCTYPE html>\n" +
        "<html>\n" +
        "<head>\n" +
        '    <meta http-equiv="content-type" content="text/html; charset=UTF-8">\n' +
        "    <title>My Project - ReDoc</title>\n" +
        '    <meta charset="utf-8">\n' +
        '    <meta name="viewport" content="width=device-width, initial-scale=1">\n' +
        '    <link rel="shortcut icon" href="https://fastapi.tiangolo.com/img/favicon.png">\n' +
        "    <style>\n" +
        "        body {\n" +
        "            margin: 0;\n" +
        "            padding: 0;\n" +
        "        }\n" +
        "    </style>\n" +
        '    <style data-styled="" data-styled-version="4.4.1"></style>\n' +
        "</head>\n" +
        "<body>\n" +
        '    <div id="redoc-container"></div>\n' +
        '    <script src="https://cdn.jsdelivr.net/npm/redoc/bundles/redoc.standalone.js"> </script>\n' +
        "    <script>\n" +
        "        var spec = " +
        content +
        "\n" +
        '        Redoc.init(spec, {}, document.getElementById("redoc-container"));\n' +
        "    </script>\n" +
        "</body>\n" +
        "</html>";

      let iframe = document.createElement("iframe");
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.display = "block";
      iframe.srcdoc = srcHtml;

      let iframeTarget = document.querySelector("#iframe-target");
      iframeTarget.innerHTML = "";
      iframeTarget.appendChild(iframe);
    });
  });

  return (
    <div
      id="iframe-target"
      style={{
        height: "95vh",
        width: "100vw",
        marginLeft: "-3rem",
        marginTop: "-2.9rem",
      }}
    >
      &nbsp;
    </div>
  );
}
