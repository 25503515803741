import React, { useMemo, useState } from "react";
import useSWR from "swr";
import FaultsModal from "./FaultsModal";
import { MultiSelectColumnFilter, SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function FaultsTable() {
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Fault",
        columns: [
          //FAULT
          {
            accessor: "id",
            Header: "ID",
            Cell: ({ cell }) =>
              cell.row.original.id ? (
                <div className="link">{cell.row.original.id}</div>
              ) : null,
          },
          {
            accessor: "status",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Status",
            defaultValue: { value: "NEW", label: "NEW" },
          },
          {
            accessor: (d) => d.date + " " + d.time,
            Header: "Date Time",
          },
          {
            accessor: "fault_severity",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Severity",
          },
          {
            accessor: "fault_code",
            Header: "Code",
          },
          {
            accessor: "fault_descriptions",
            Header: "Description",
          },
          {
            accessor: "comments",
            Header: "Comments",
          },
        ],
      },

      // DDRM
      {
        Header: "DDRM",
        columns: [
          {
            accessor: "ddrm_serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) => (d.ddrm_functional ? "TRUE" : "FALSE"),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Functional",
          },
          {
            accessor: "ddrm_comments",
            Header: "Comments",
          },
        ],
      },

      // MOUTHPIECE
      {
        Header: "Mouthpiece",
        columns: [
          {
            accessor: "serial_number",
            Header: "Serial",
          },
          {
            accessor: (d) => (d.mouthpiece_functional ? "TRUE" : "FALSE"),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: " Functional ", // spaces are here otherwise datatable will not render due to something with datatables
          },
          {
            accessor: "location",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Location",
          },
          {
            accessor: "mouthpiece_comments",
            Header: "Comments",
          },
        ],
      },

      // PLAYER
      {
        Header: "Player",
        columns: [
          {
            accessor: "mgs_player_id",
            Header: "ID",
          },
          {
            accessor: (d) => d.last_name + ", " + d.first_name,
            Header: "Name",
          },
          {
            accessor: "organization",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Organization",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: (d) => (d.study_participation ? "ACTIVE" : "INACTIVE"),
            Header: "Status",
            Filter: SelectColumnFilter,
            filter: "exactText",
            id: "study_participation",
          },
        ],
      },
    ],
    []
  );

  const { data: faults } = useSWR("faults");

  // create custom initial state for this table for sorting and filtering.
  const initialState = useMemo(() => {
    return {
      sortBy: [
        {
          id: "id",
          desc: false,
        },
      ],
      filters: [
        {
          id: "status",
          value: "NEW",
        },
      ],
    };
  }, []);

  return (
    <>
      <Table
        className="full-screen faults"
        columns={columns}
        data={faults}
        onClick={setRow}
        initialState={initialState}
      />
      {row ? <FaultsModal row={row} setRow={setRow} /> : null}
    </>
  );
}
