import Alert from "react-bootstrap/Alert";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { CSVReader } from "react-papaparse";
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import useSWR from "swr";
import * as yup from "yup";
import { SelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";
import config from "config.json";
import { buildErrorsList } from "utils/helpers";

export default function PlayersAddTable() {
  const [alert, setAlert] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [data, setData] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [show, setShow] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: (d) => (d.valid ? "True" : "False"),
            Cell: ({ cell }) => {
              const valid = cell.row.original.valid;
              const color = valid ? "green" : "blue";
              const icon = valid ? faCheckCircle : faExclamationCircle;
              return (
                <span>
                  {/*{cell.row.original.valid.toString()}{" "}*/}
                  <FontAwesomeIcon color={color} icon={icon} />
                </span>
              );
            },
            Header: "Valid",
            id: "valid",
          },
          {
            accessor: (d) => d.errors,
            Cell: ({ cell }) => {
              if (Array.isArray(cell.row.original.errors)) {
                const errorsList = buildErrorsList(cell.row.original.errors);
                return (
                  <ul
                    style={{ textAlign: "left", paddingLeft: 0 }}
                    dangerouslySetInnerHTML={{ __html: errorsList }}
                  />
                );
              } else {
                return null;
              }
            },
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Errors",
            id: "errors",
          },
          {
            accessor: (d) =>
              d.response
                ? d.response.status === 201
                  ? "Success"
                  : "Errors"
                : "Unsubmitted",
            Cell: ({ cell }) => {
              const response = cell.row.original.response;
              let color, icon, success;
              if (response) {
                success = response.status === 201;
                color = success ? "green" : "red";
                icon = success ? faCheckCircle : faTimesCircle;
                return <FontAwesomeIcon color={color} icon={icon} />;
              } else return null;
            },
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Submitted",
          },
          {
            accessor: "last_name",
            Header: "Last Name",
          },
          {
            accessor: "first_name",
            Header: "First Name",
          },
          {
            accessor: "nickname",
            Header: "Nickname",
          },
          {
            accessor: "image",
            Header: "Image",
          },
          {
            accessor: "organization",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Organization",
          },
          {
            accessor: "ngs_position",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Postion",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: (d) => (d.comments ? d.comments : " "),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Comments",
            id: "comments",
          },
        ],
        Header: "Player",
      },
    ],
    []
  );

  const { data: organizations } = useSWR("organizations");

  const template = {
    data: [
      {
        last_name: "Smith",
        first_name: "John",
        nickname: "Jacob Jingleheimer",
        // image: "",
        organization: "BIO",
        ngs_position: "LB",
        roster_number: "1",
        comments:
          "John Smith (baptized 6 January 1580 – 21 June 1631) was an English soldier, explorer, colonial governor, Admiral of New England, and author.",
      },
    ],
    filename: "players_template.csv",
    headers: [
      "last_name",
      "first_name",
      "nickname",
      // "image",
      "organization",
      "ngs_position",
      "roster_number",
      "comments",
    ],
  };

  /**
   * Resets the data and removes table modal if the user cancels
   */
  const handleClearClick = () => {
    setIsReset(true);
    setData([]);
    setSubmitDisabled(true);
  };

  /**
   * Handles the submission of the data to the REST API
   */
  const handleConfirmClick = () => {
    setConfirmDisabled(true);
    setLoading(true);

    Promise.allSettled(
      data.map((row) => {
        if (row.valid) {
          const body = [JSON.parse(JSON.stringify(row))];
          delete body.response;
          delete body.valid;
          return axios.post("players", body);
        } else {
          return null;
        }
      })
    )
      .then((outcome) => {
        setData(
          data.map((row, index) => {
            row.response =
              outcome[index].status === "fulfilled"
                ? outcome[index].value
                : { status: parseInt(outcome[index].reason) };
            return row;
          })
        );
        setSubmitDisabled(true);
        setShow(false);
        setLoading(false);
        setErrored(false);
      })
      .catch((error) => {
        console.log(error.response);
        setErrored(true);
      });
  };

  /**
   * Checks to ensure that the file is the correct type and validates the data
   * @param players
   * @param file
   */
  const handleFileDrop = (players, file) => {
    if (
      !["application/vnd.ms-excel", "text/csv", "text/plain", ".csv"].includes(
        file.type
      )
    ) {
      return setAlert("Invalid file type.");
    }
    const schema = yup.object({
      first_name: yup
        .string()
        .matches(/^[a-zA-Z0-9#'.-]{2,50}$/)
        .required(),
      last_name: yup
        .string()
        .matches(/^[a-zA-Z0-9#'.-]{2,50}$/)
        .required(),
      nickname: yup.string().matches(/^[a-zA-Z0-9#' .,-]{0,50}$/),
      image: yup.string().matches(/(.*\/.*\/.*\.(?:png|jpg|gif|svg|jpeg))/i),
      // .matches(/(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg))/i),
      organization: yup
        .string()
        .test("organization", "Organization not recognized.", function (input) {
          return organizations
            .map((organization) => organization.abbreviation)
            .includes(input);
        }),
      ngs_position: yup
        .string()
        .required()
        .test("position", "Position not recognized.", function (input) {
          return config.playerPositions.includes(input);
        }),
      roster_number: yup
        .string()
        .matches(
          /^(([0-9]|[1-9][0-9])[OD]{0,1}$|TBD)/,
          "Roster must be 0-99 (trailing D or O optional) or TBD."
        )
        .required("Roster is required (use TBD if unknown)."),
      comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters."),
    });
    setIsReset(false);

    Promise.allSettled(
      players.map(async (player) => {
        // schema.isValid(player.data)
        await schema.validate(player.data, { abortEarly: false });
      })
    )
      .then((outcome) => {
        setData(
          players.map((player, index) => {
            player.data.comments = player.data.comments.trim()
              ? player.data.comments.trim()
              : null;
            player.data.response = null;
            player.data.valid = outcome[index].status === "fulfilled";
            player.data.errors =
              outcome[index].status === "rejected"
                ? outcome[index].reason.errors
                : false;
            return player.data;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
    setSubmitDisabled(false);
  };

  /**
   * Closes modal window
   */
  const handleModalClose = () => {
    setConfirmDisabled(true);
    setShow(false);
  };

  /**
   * Triggers showing of confirmation modal
   */
  const handleSubmitClick = () => {
    setConfirmDisabled(false);
    setShow(true);
  };

  return (
    <>
      <div
        className={
          data.length
            ? "d-none"
            : "align-items-start d-flex justify-content-center"
        }
      >
        <div className="align-items-start d-flex flex-row">
          <Col>
            <div className="align-items-center d-flex flex-column justify-content-center pe-5 text-left">
              <div className="instructions mt-4">
                <h4>Player Template Instructions</h4>
                <p>To properly format the players file, please:</p>
                <ol className="w-full-width">
                  <li className="mb-3">
                    Use abbreviation for organization. Organization
                    abbreviations include:
                    <br />
                    {organizations.map((org, index) => (
                      <>
                        <span className="me-1">
                          <pre className="d-inline">{org.abbreviation}</pre>
                          {index < organizations.length - 1 ? (
                            <span>,</span>
                          ) : (
                            ""
                          )}
                        </span>
                        {(index + 1) % 16 === 0 ? <br /> : ""}
                      </>
                    ))}
                  </li>
                  <li className="mb-3">
                    Do not use punctuation such as periods for names or
                    nicknames such as T.J. (e.g., T.J. should be input as{" "}
                    <pre className="d-inline">TJ</pre>)
                  </li>
                  <li className="mb-3">
                    Include the players position. Use{" "}
                    <pre className="d-inline">TBD</pre> if the position is
                    currently unknown. Options include:
                    <br />
                    {config.playerPositions.map((position, index) => (
                      <>
                        <span className="me-1">
                          <pre className="d-inline">{position}</pre>
                          {index < config.playerPositions.length - 1 ? (
                            <span key={index}>,</span>
                          ) : (
                            ""
                          )}
                        </span>
                        {(index + 1) % 25 === 0 ? <br /> : ""}
                      </>
                    ))}
                  </li>
                  <li className="mb-3">
                    Roster numbers can be <pre className="d-inline">1-99</pre>{" "}
                    with or without trailing “O” or “D” or{" "}
                    <pre className="d-inline">TBD</pre>. In the case of players
                    from the same team having the same number, add an "O" if an
                    offensive player or "D" for a defensive player. (e.g. There
                    are two players with the roster number 25 at the start of
                    the season. One is a quarterback, the other a defensive
                    lineman. Enter the quarterback as 25O and the defensive
                    lineman as 25D)
                  </li>
                </ol>
              </div>
            </div>
          </Col>
          <Col className="text-center">
            {alert ? (
              <div className="d-flex justify-content-center mb-4">
                <Alert variant="danger">{alert}</Alert>
              </div>
            ) : null}

            <div className="drop-zone mb-4 mt-4">
              <CSVReader
                addRemoveButton
                config={{ header: true, skipEmptyLines: "greedy" }}
                isReset={isReset}
                noClick
                noProgressBar
                onDrop={handleFileDrop}
                onError={(error) => console.log(error)}
                onRemoveFile={() => (alert ? setAlert("") : null)}
              >
                <span>Drag and drop a file here to import players.</span>
              </CSVReader>
              <div className="mt-3">
                <CSVLink {...template}>Download Player Ingest Template</CSVLink>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {data.length ? (
        <>
          <Table
            className="full-screen-with-buttons mb-4"
            columns={columns}
            data={data}
          />
          <div className="d-flex justify-content-end">
            <Button
              className="mr-2"
              onClick={handleClearClick}
              type="button"
              variant="secondary"
            >
              Clear
            </Button>
            <div className="">&nbsp;</div>
            <Button
              disabled={submitDisabled}
              onClick={handleSubmitClick}
              type="button"
              variant="danger"
            >
              Submit
            </Button>
          </div>
          <Modal
            animation={false}
            background="static"
            centered
            onHide={() => handleModalClose()}
            show={show}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Confirm Addition</Modal.Title>
            </Modal.Header>
            <Modal.Body>{`Add ${
              data.filter((row) => row.valid).length
            } player(s) to BASIM?`}</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => handleModalClose()}
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>

              <Button
                disabled={confirmDisabled}
                onClick={() => handleConfirmClick()}
                type="button"
                variant="danger"
              >
                Confirm
                {loading === true ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="light"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  ""
                )}
                {errored === true ? (
                  <span className="errored">
                    <img
                      src="/img/icn-error-white.svg"
                      alt="errored..."
                      style={{ width: "20px", marginLeft: "2%" }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : null}
    </>
  );
}
