import React from "react";
import useSWR from "swr";
import { Card } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { currentOrgState } from "components/shared/States";

export default function CardOrders(props) {
  const currentOrg = useRecoilValue(currentOrgState);
  const { data: allOrders } = useSWR(`mouthpieces/ordered/${currentOrg}`);

  const filteredOrders = allOrders.filter(
    (orders) => orders.status !== "DELIVERED"
  );

  return (
    <Card className="shadow-sm bg-white rounded mb-4">
      <Card.Body className="text-center">
        <Card.Title className="text-secondary">Mouthpiece Orders</Card.Title>
        <h1 className="display-3" style={{ color: "#2752a5" }}>
          {filteredOrders.length}
        </h1>
      </Card.Body>
    </Card>
  );
}
