import React, { useMemo, useState } from "react";
import useSWR from "swr";
import PlayersModal from "./PlayersModal";
import { MultiSelectColumnFilter } from "utils/filters";
import Table from "components/shared/Table";

export default function PlayersTable() {
  const [row, setRow] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Player",
        columns: [
          {
            accessor: "mgs_player_id",
            Header: "ID",
            Cell: ({ cell }) =>
              cell.row.original.mgs_player_id ? (
                <div className="link">{cell.row.original.mgs_player_id}</div>
              ) : null,
          },
          {
            accessor: (d) => (d.study_participation ? "ACTIVE" : "INACTIVE"),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Status",
            id: "study_participation",
          },
          {
            accessor: (d) =>
              d.last_name !== null
                ? d.last_name +
                  ", " +
                  d.first_name +
                  (d.nickname ? " " + d.nickname : "")
                : "",
            Header: "Name",
          },
          {
            accessor: "organization",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Organization",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
          {
            accessor: "ngs_position",
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Position",
          },
          {
            accessor: "comments",
            Header: "Comments",
          },
        ],
      },
      {
        Header: "Scan",
        columns: [
          {
            accessor: (d) => (d.scan_type ? d.scan_type : " "),
            Filter: MultiSelectColumnFilter,
            filter: "multiple",
            Header: "Type",
            id: "scan_type",
          },
          {
            accessor: "scan_date",
            Header: "Date",
          },
        ],
      },
    ],
    []
  );

  const { data: players } = useSWR("players");

  // add custom states for this table here
  const initialState = useMemo(() => {
    return {
      sortBy: [
        {
          id: "study_participation",
          desc: false,
        },
        {
          id: "mgs_player_id",
          desc: false,
        },
        {
          id: "last_name",
          desc: false,
        },
      ],
    };
  }, []);

  return (
    <>
      <Table
        className="full-screen players"
        columns={columns}
        data={players}
        initialState={initialState}
        onClick={setRow}
      />
      {row ? <PlayersModal row={row} setRow={setRow} /> : null}
    </>
  );
}
