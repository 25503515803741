import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";

export default function OrganizationsModal({ organizations, row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const initialValues = {
    abbreviation: row.abbreviation ? row.abbreviation : "",
    contact_name: row.contact_name ? row.contact_name : "",
    contact_email: row.contact_email ? row.contact_email : "",
    contact_phone: row.contact_phone ? row.contact_phone : "",
    contact_address_1: row.contact_address_1 ? row.contact_address_1 : "",
    contact_address_2: row.contact_address_2 ? row.contact_address_2 : "",
    contact_city: row.contact_city ? row.contact_city : "",
    contact_state: row.contact_state ? row.contact_state : "",
    contact_zip_code: row.contact_zip_code ? row.contact_zip_code : "",
    shipping_name: row.shipping_name ? row.shipping_name : "",
    shipping_address_1: row.shipping_address_1 ? row.shipping_address_1 : "",
    shipping_address_2: row.shipping_address_2 ? row.shipping_address_2 : "",
    shipping_city: row.shipping_city ? row.shipping_city : "",
    shipping_state: row.shipping_state ? row.shipping_state : "",
    shipping_zip_code: row.shipping_zip_code ? row.shipping_zip_code : "",
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const organizations = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "contact_name":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_email":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_phone":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_address_1":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_address_2":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_city":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_state":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "contact_zip_code":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_name":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_address_1":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_address_2":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_city":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_state":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            case "shipping_zip_code":
              organizations[key] =
                values[key].trim() !== "" ? values[key].trim() : null;
              break;
            default:
              organizations[key] = values[key];
          }
        }
      }

      const requests = [];
      if (Object.keys(organizations).length) {
        requests.push(
          axios.put("organizations", {
            abbreviation: row.abbreviation,
            contact_name: row.contact_name,
            contact_email: row.contact_email,
            contact_phone: row.contact_phone,
            contact_address_1: row.contact_address_1,
            contact_address_2: row.contact_address_2,
            contact_city: row.contact_city,
            contact_state: row.contact_state,
            contact_zip_code: row.contact_zip_code,
            shipping_name: row.shipping_name,
            shipping_address_1: row.shipping_address_1,
            shipping_address_2: row.shipping_address_2,
            shipping_city: row.shipping_city,
            shipping_state: row.shipping_state,
            shipping_zip_code: row.shipping_zip_code,
            ...organizations,
          })
        );
      }

      axios
        .all(requests)
        .then(() => {
          mutate("organizations");
          setLoading(false);
          setErrored(false);
          setRow(null);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
          setErrored(true);
        });
    },

    validate: function (values) {
      values.contact_name = values.contact_name.trim();
      values.contact_email = values.contact_email.trim();
      values.contact_phone = values.contact_phone.trim();
      values.contact_address_1 = values.contact_address_1.trim();
      values.contact_address_2 = values.contact_address_2.trim();
      values.contact_city = values.contact_city.trim();
      values.contact_state = values.contact_state.trim();
      values.contact_zip_code = values.contact_zip_code.trim();
      values.shipping_name = values.shipping_name.trim();
      values.shipping_address_1 = values.shipping_address_1.trim();
      values.shipping_address_2 = values.shipping_address_2.trim();
      values.shipping_city = values.shipping_city.trim();
      values.shipping_state = values.shipping_state.trim();
      values.shipping_zip_code = values.shipping_zip_code.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      contact_name: yup.string().max(255),
      contact_email: yup.string().email().max(255),
      contact_phone: yup.string().max(20),
      contact_address_1: yup.string().max(255),
      contact_address_2: yup.string().max(255),
      contact_city: yup.string().max(255),
      contact_state: yup.string().max(2),
      contact_zip_code: yup.string().max(10),
      shipping_name: yup.string().max(255),
      shipping_address_1: yup.string().max(255),
      shipping_address_2: yup.string().max(255),
      shipping_city: yup.string().max(255),
      shipping_state: yup.string().max(2),
      shipping_zip_code: yup.string().max(10),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${row.abbreviation}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <h4 className="mb-2">Organization Contact</h4>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.contact_name && formik.touched.contact_name
                  }
                  name="contact_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.contact_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.contact_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.contact_email &&
                    formik.touched.contact_email
                  }
                  name="contact_email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.contact_email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.contact_email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.contact_phone &&
                    formik.touched.contact_phone
                  }
                  name="contact_phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.contact_phone}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.contact_phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.contact_address_1}
                name="contact_address_1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.contact_address_1}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_address_1}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.contact_address_2}
                name="contact_address_2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.contact_address_2}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_address_2}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Form.Label>City</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.contact_city}
                name="contact_city"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.contact_city}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_city}
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label>State</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.contact_state}
                name="contact_state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.contact_state}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_state}
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label>Zip</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.contact_zip_code}
                name="contact_zip_code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.contact_zip_code}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_zip_code}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <h4 className="mb-2">Shipping Contact</h4>
              <input
                type="hidden"
                name="abbreviation"
                value={formik.values.abbreviation}
              />
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.shipping_name &&
                    formik.touched.shipping_name
                  }
                  name="shipping_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.shipping_name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.shipping_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.shipping_address_1}
                name="shipping_address_1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.shipping_address_1}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_address_1}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.shipping_address_2}
                name="shipping_address_2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.shipping_address_2}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_address_2}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>City</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.shipping_city}
                name="shipping_city"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.shipping_city}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_city}
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label>State</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.shipping_state}
                name="shipping_state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.shipping_state}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_state}
              </Form.Control.Feedback>
            </Col>
            <Col>
              <Form.Label>Zip</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.shipping_zip_code}
                name="shipping_zip_code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.shipping_zip_code}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.shipping_zip_code}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update{" "}
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
