import Alert from "react-bootstrap/Alert";
import { Auth } from "aws-amplify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

export default function NewPasswordForm({
  setChallenge,
  setUnconfirmedUser,
  unconfirmedUser,
}) {
  const [alert, setAlert] = useState(null);

  const formik = useFormik({
    initialValues: {
      confirmedPassword: "",
      password: "",
    },
    onSubmit: (values) => {
      Auth.completeNewPassword(unconfirmedUser, values.password)
        .then((user) => {
          if (alert) setAlert(null);
          setChallenge(user.challengeName);
          setUnconfirmedUser(user);
        })
        .catch((err) => {
          switch (err.code) {
            default:
              setAlert("Something went horribly wrong...");
          }
        });
    },
    validationSchema: yup.object().shape({
      confirmedPassword: yup
        .string()
        .test("match", "Passwords do not match.", function (confirmedPassword) {
          return confirmedPassword === this.parent.password;
        }),
      password: yup
        .string()
        .min(12, "Password must be at least 12 characters.")
        .required("Password is required."),
    }),
  });

  return (
    <div className="align-items-center d-flex full-screen justify-content-center">
      <div>
        {alert ? (
          <div className="d-flex justify-content-center mb-4">
            <Alert variant="danger">{alert}</Alert>
          </div>
        ) : null}
        <div className="d-flex justify-content-center">
          <Form
            className="centered-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.password && formik.touched.password}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                isInvalid={
                  !!formik.errors.confirmedPassword &&
                  formik.touched.confirmedPassword
                }
                name="confirmedPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.confirmedPassword}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.confirmedPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Button block className="mt-4" type="submit" variant="primary">
              Update
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
