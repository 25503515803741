import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  cognitoGroupState,
  currentOrgState,
  navigationValueState,
  playerDetailsIdState,
  playersFilterState,
  showPlayerDetailsState,
  tablePageIndexState,
  textState,
} from "components/shared/States";

export default function Navigation({ group, handleSignOut, cogTeam }) {
  const setNavigationValue = useSetRecoilState(navigationValueState);
  const setCognitoGroup = useSetRecoilState(cognitoGroupState);
  const [currentOrg, setCurrentOrg] = useRecoilState(currentOrgState);
  const setTablePageIndex = useSetRecoilState(tablePageIndexState);
  const setShowPlayerDetails = useSetRecoilState(showPlayerDetailsState);
  const setPlayerDetailsId = useSetRecoilState(playerDetailsIdState);
  const setPlayersFilter = useSetRecoilState(playersFilterState);
  const setText = useSetRecoilState(textState);

  useEffect(() => {
    setCognitoGroup(group);
    if (!currentOrg) {
      setCurrentOrg(cogTeam);
    }
  }, [group, cogTeam, currentOrg, setCurrentOrg, setCognitoGroup]);

  /**
   * Reset the teams page setup to default when using navigation links
   * @param path
   */
  function handleNavigationClick(path) {
    setTablePageIndex(0);
    setNavigationValue(path);
    setPlayersFilter("none");
    setPlayerDetailsId(0);
    setShowPlayerDetails(false);
    setText("");
  }

  switch (group) {
    case "biocore":
      return (
        <>
          <Nav>
            <Navbar.Brand
              as={Link}
              to="/"
              onClick={(e) => handleNavigationClick("/")}
            >
              Biocore Advanced Sensors
            </Navbar.Brand>

            <Nav.Link
              as={Link}
              to="/ddrcs"
              onClick={(e) => handleNavigationClick("/ddrcs")}
            >
              DDRCs
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/ddrms"
              onClick={(e) => handleNavigationClick("/ddrms")}
            >
              DDRMs
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/faults"
              onClick={(e) => handleNavigationClick("/faults")}
            >
              Faults
            </Nav.Link>
            <NavDropdown title="Mouthpieces">
              <NavDropdown.Item
                as={Link}
                to="/mouthpieces"
                onClick={(e) => handleNavigationClick("/mouthpieces")}
              >
                View
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/mouthpieces/order"
                onClick={(e) => handleNavigationClick("/mouthpieces/order")}
              >
                Order
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Players">
              <NavDropdown.Item
                as={Link}
                to="/players"
                onClick={(e) => handleNavigationClick("/players")}
              >
                View
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/players/add"
                onClick={(e) => handleNavigationClick("/players/add")}
              >
                Add
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/organizations"
              onClick={(e) => handleNavigationClick("/organizations")}
            >
              Organizations
            </Nav.Link>
            <NavDropdown title="Docs">
              <NavDropdown.Item
                as={Link}
                to="/documentation/redoc"
                onClick={(e) => handleNavigationClick("/documentation/redoc")}
              >
                BASIM
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://case.basim.biocorellc.io/redoc"
                target="_blank"
              >
                Case
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://opro.test.basim.biocorellc.io/redoc"
                target="_blank"
              >
                OPRO
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://tools.basim.biocorellc.io/redoc"
                target="_blank"
              >
                Tools
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Button
            onClick={() => {
              setTablePageIndex(0);
              handleSignOut();
            }}
            type="button"
            variant="outline-light"
          >
            Sign out
          </Button>
        </>
      );
    case "zebra":
      return (
        <>
          <Nav>
            <Navbar.Brand
              as={Link}
              to="/"
              onClick={() => setNavigationValue("/")}
            >
              Biocore Advanced Sensors
            </Navbar.Brand>
          </Nav>
          <Button
            onClick={() => handleSignOut()}
            type="button"
            variant="outline-light"
          >
            Sign out
          </Button>
        </>
      );
    case "team":
      return (
        <>
          <Nav>
            <Navbar.Brand
              as={Link}
              to="/"
              onClick={() => setNavigationValue("/")}
            >
              Biocore Advanced Sensors
            </Navbar.Brand>
          </Nav>
          <Button
            onClick={() => handleSignOut()}
            type="button"
            variant="outline-light"
          >
            Sign out
          </Button>
        </>
      );
    default:
      return (
        <>
          <Nav>
            <Navbar.Brand
              as={Link}
              to="/"
              onClick={() => setNavigationValue("/")}
            >
              Biocore Advanced Sensors
            </Navbar.Brand>
          </Nav>
        </>
      );
  }
}
