import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { mutate } from "swr";
import React, { useState } from "react";
import { useFormik } from "formik";

export default function MouthpiecesPlayerIdModal({
  id,
  organization,
  row,
  setRow,
}) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const initialValues = {
    functional: row.ddrm_serial_number
      ? row.functional
        ? "True"
        : "False"
      : "",
    location: row.location ? row.location : "",
    status: row.status,
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const body = {
        serial_number: row.ddrm_serial_number,
      };
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "functional":
              body[key] = values[key] === "True";
              break;
            default:
              body[key] = values[key];
          }
        }
      }
      axios
        .put("ddrms", body)
        .then(() => {
          mutate(`mouthpieces/player/${id}`);
          setRow(null);
          setDisabled(false);
          setLoading(false);
          setErrored(false);
        })
        .catch((error) => {
          console.log(error.response);
          setErrorMsg(error.response);
          setErrored(true);
        });
    },
    validate: function (values) {
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
  });

  return (
    <Modal
      animation={false}
      background="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${
            row.mouthpiece_serial_number ? row.mouthpiece_serial_number : "N/A"
          }`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Functional</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="functional"
                  onChange={formik.handleChange}
                  value={formik.values.functional}
                >
                  {row.ddrm_serial_number ? (
                    <>
                      <option value="False">False</option>
                      <option value="True">True</option>
                    </>
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  as="select"
                  disabled={!row.ddrm_serial_number}
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                >
                  {row.ddrm_serial_number ? (
                    [organization, "LOST", "BIO", "OPRO", "GL"]
                      .sort()
                      .map((location) => {
                        return (
                          <option key={location} value={location}>
                            {location}
                          </option>
                        );
                      })
                  ) : (
                    <option value=""></option>
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {errorMsg ? (
            <Row>
              <Col>
                <div className="d-flex justify-content-center mb-4">
                  <Alert variant="danger">{errorMsg}</Alert>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
