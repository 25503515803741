import { Card } from "react-bootstrap";
import React from "react";
import { useRecoilValue } from "recoil";
import { managedTeamState } from "components/shared/States";

export default function CardLowInventory() {
  const managedTeam = useRecoilValue(managedTeamState);

  // get the players with low inventory
  const playersFiltered = managedTeam.activePlayers.filter(
    (player) =>
      player.mouthpiece_counts.functional + player.mouthpiece_counts.ordered < 3
  );

  return (
    <Card className="shadow-sm bg-white rounded mb-4">
      <Card.Body className="text-center">
        <Card.Title className="text-secondary">
          Players with Low Inventory
        </Card.Title>
        <h1 className="display-3 text-info">{playersFiltered.length}</h1>
      </Card.Body>
    </Card>
  );
}
