import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useMemo, useState } from "react";
import useSWR, { mutate } from "swr";

import { SelectColumnFilter } from "../../../../utils/filters";
import Table from "../../../shared/Table";

export default function OrderedTable() {
  const [row, setRow] = useState(null);
  const [show, setShow] = useState(false);

  const columns = useMemo(
    () => [
      {
        columns: [
          {
            accessor: "mouthpiece_serial_number",
            Header: "Serial",
          },
          {
            accessor: "type",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Type",
          },
          {
            accessor: "colors",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Colors",
          },
        ],
        Header: "Mouthpiece",
      },
      {
        columns: [
          {
            accessor: "case_color",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Color",
          },
        ],
        Header: "Case",
      },
      {
        columns: [
          {
            accessor: (d) => d.dentition_quantity.toString(),
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Quantity",
            id: "dentition_quantity",
          },
        ],
        Header: "Dentition",
      },
      {
        columns: [
          {
            accessor: "organization",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Organization",
          },
          {
            accessor: (d) => `${d.last_name}, ${d.first_name}`,
            Header: "Name",
            id: "name",
          },
          {
            accessor: "ngs_position",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Position",
          },
          {
            accessor: "roster_number",
            Header: "Roster",
          },
        ],
        Header: "Player",
      },
      {
        columns: [
          {
            accessor: (d) => d.requested_delivery_date.split("T")[0],
            Header: "Requested Delivery",
            id: "requested_delivery_date",
          },
          {
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: "exactText",
            Header: "Status",
          },
          {
            accessor: "tracking_number",
            Cell: ({ cell }) => {
              const trackingNumber = cell.row.original.tracking_number;
              if (trackingNumber && trackingNumber.length === 10) {
                return (
                  <a
                    href={`https://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                );
              } else if (trackingNumber && trackingNumber.length === 12) {
                return (
                  <a
                    href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {trackingNumber}
                  </a>
                );
              } else if (trackingNumber) {
                return trackingNumber;
              } else {
                return null;
              }
            },
            Header: "Tracking",
          },
          {
            accessor: "comments",
            Header: "Comments",
          },
        ],
        Header: "Order",
      },
      {
        columns: [
          {
            Cell: ({ cell }) =>
              cell.row.original.status === "SHIPPED" ? (
                <div className="negative-margin">
                  <Button
                    onClick={() => handleReceiveClick(cell.row.original)}
                    size="sm"
                    type="button"
                    variant="danger"
                  >
                    Receive
                  </Button>
                </div>
              ) : null,
            Header: "Receive Mouthpiece",
          },
        ],
        Header: "Action",
      },
    ],
    []
  );

  const { data: mouthpieces } = useSWR("mouthpieces/ordered");

  const handleConfirmClick = () => {
    const today = new Date();
    axios
      .all([
        axios.put("ddrms", {
          serial_number: row.ddrm_serial_number,
          location: "ZEB",
        }),
        axios.put("mouthpieces", {
          order_number: row.order_number,
          delivery_date: `${today.getFullYear()}-${String(
            today.getMonth() + 1
          ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`,
          status: "Delivered",
        }),
      ])
      .then(() => {
        mutate("mouthpieces/ordered");
        handleModalClose();
      })
      .catch((err) => console.log(err));
  };
  const handleModalClose = () => {
    setRow(null);
    setShow(false);
  };
  const handleReceiveClick = (row) => {
    setRow(row);
    setShow(true);
  };

  return (
    <>
      <Table className="half-screen" columns={columns} data={mouthpieces} />
      <Modal
        animation={false}
        background="static"
        centered
        onHide={() => handleModalClose()}
        show={show}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Confirm Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {row ? `Receive mouthpiece ${row.mouthpiece_serial_number}?` : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleModalClose()}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmClick()}
            type="button"
            variant="danger"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
