import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { mutate } from "swr";
import { useFormik } from "formik";
import * as yup from "yup";
import Spinner from "react-bootstrap/Spinner";

export default function PlayersModal({ row, setRow }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  const initialValues = {
    roster_number: row.roster_number ? row.roster_number : "",
    nickname: row.nickname ? row.nickname : "",
    study_participation: row.study_participation ? "ACTIVE" : "INACTIVE",
    comments: row.comments ? row.comments : "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: function (values) {
      setDisabled(true);
      setLoading(true);

      const players = {};
      const studyStatus = {};
      for (const key in values) {
        if (values[key] !== initialValues[key]) {
          switch (key) {
            case "comments":
              players[key] = values[key].trim() ? values[key].trim() : null;
              break;
            case "study_participation":
              studyStatus[key] = values[key] === "ACTIVE";
              break;
            default:
              players[key] = values[key];
          }
        }
      }
      const requests = [];
      if (Object.keys(players).length) {
        requests.push(
          axios.put("players", { mgs_player_id: row.mgs_player_id, ...players })
        );
      }
      if (Object.keys(studyStatus).length) {
        requests.push(
          axios.put("study_status", {
            mgs_player_id: row.mgs_player_id,
            ...studyStatus,
          })
        );
      }
      axios
        .all(requests)
        .then(() => {
          mutate("players").then((result) => {
            setRow(null);
            setDisabled(false);
            setLoading(false);
            setErrored(false);
          });
        })
        .catch((error) => {
          console.log(error.response);
          setErrored(true);
        });
    },
    validate: function (values) {
      values.comments = values.comments.trim();
      const update = JSON.stringify(initialValues) !== JSON.stringify(values);
      if (update) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    },
    validationSchema: yup.object().shape({
      nickname: yup
        .string()
        .max(25, "Nicknames must be less than 25 characters long."),
      comments: yup
        .string()
        .max(500, "Comments must be at most 500 characters long."),
      roster_number: yup
        .string()
        .required("Roster is required (use TBD if unknown).")
        .matches(
          /^(([0-9]|[1-9][0-9])[OD]{0,1}$|TBD)/,
          "Roster must be 0-99 (trailing D or O optional) or TBD."
        ),
    }),
  });

  return (
    <Modal
      animation={false}
      backdrop="static"
      centered
      onHide={() => setRow(null)}
      show={true}
      size="lg"
    >
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Modal.Header>
          <Modal.Title>{`Edit ${row.mgs_player_id}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Nickname</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.nickname && formik.touched.nickname
                  }
                  name="nickname"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.nickname}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.nickname}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Roster</Form.Label>
                <Form.Control
                  isInvalid={
                    !!formik.errors.roster_number &&
                    formik.touched.roster_number
                  }
                  name="roster_number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.roster_number}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.roster_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="study_participation"
                  onChange={formik.handleChange}
                  value={formik.values.study_participation}
                  className="form-select"
                >
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                isInvalid={!!formik.errors.comments}
                name="comments"
                onChange={formik.handleChange}
                rows={3}
                value={formik.values.comments}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.comments}
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setRow(null)}
            type="button"
            variant="secondary"
          >
            Cancel
          </Button>
          <Button disabled={disabled} type="submit" variant="danger">
            Update{" "}
            {loading === true ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="light"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              ""
            )}
            {errored === true ? (
              <span className="errored">
                <img
                  src="/img/icn-error-white.svg"
                  alt="errored..."
                  style={{ width: "20px", marginLeft: "2%" }}
                />
              </span>
            ) : (
              ""
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
